import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../core/auth';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DateAdapter } from '@angular/material/core';
import { inject } from '@angular/core/testing';
import { Center } from '../../../../core/center/center.model';
import { CenterService } from '../../../../core/center/center.service';
import { Vacancy } from '../../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../../core/vacancy/vacancies.service';
import { VacancyEditComponent } from '../../vacancy-management/vacancy-edit/vacancy-edit.component';
import { Officer } from '../../../../core/auth/_models/officer.models';
import { Posting } from '../../../../core/vacancy/posting.model';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { PostingConfig } from '../../../../core/vacancy/postingconfig.model';
import { BatchConfig } from '../../../../core/vacancy/batchconfig.model';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
	selector: 'kt-edit-portal-configuration',
	templateUrl: './edit-portal-configuration.component.html',
	styleUrls: ['./edit-portal-configuration.component.scss']
})
export class EditPortalConfigurationComponent implements OnInit {
	user: User;
	posting: Posting;
	center: Center;
	vacancy: Vacancy;
	officer: Officer;
	userId$: Observable<number>;
	oldUser: User;
	cName: string;
	slogan: string;
	address: string;
	tel: string;
	email: string;
	role: number;
	userType: string;
	id: number;
	states: any;
	officers: any;
	cities: any;
	stateId: number;
	cityId: number;
	city: string;
	centers: any;
	mdcnQuota: any;
	officerQuota: any;
	centerQuota: any;
	currentBatch: any;
	openingDate: any;
	closingDate: any;
	closingTime: any;
	notificationMessage: any;
	@BlockUI() blockUI: NgBlockUI;


	selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	postingForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private userFB: FormBuilder,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private store: Store<AppState>,
		private authService: AuthService,
		private centerService: CenterService,
		private vacancyService: VacanciesService,
		private dialogRef: MatDialogRef<EditPortalConfigurationComponent>,
		private layoutConfigService: LayoutConfigService,
		private dateAdapter: DateAdapter<Date>,

		@Inject(MAT_DIALOG_DATA) data
	) {
		if (data) {
			this.id = data.id;

			this.currentBatch = data.batchId
			this.openingDate = data.openingDate
			this.closingDate = data.closingDate
			this.closingTime = data.closingTime
			this.notificationMessage = data.notificationMessage


		}
		this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
	}


	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription = this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;
						this.rolesSubject.next(this.user.roles);
						this.addressSubject.next(this.user.address);
						this.soicialNetworksSubject.next(this.user.socialNetworks);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
				this.posting = new Posting();
				this.user = new User();
				this.center = new Center();
				this.vacancy = new Vacancy();
				this.vacancy.clear();
				this.center.clear();
				this.posting.clear();
				this.user.clear();
				this.rolesSubject.next(this.user.roles);
				this.addressSubject.next(this.user.address);
				this.soicialNetworksSubject.next(this.user.socialNetworks);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);

		//alert(JSON.stringify(this.user))
		//this.getAllStates()
		this.getAllCenters()
		this.getALLOfficers();
		this.userType = this.getUSerType();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();

		if (!this.user.id) {
			this.subheaderService.setTitle('Create Posting');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Posting Management', page: `user-management` },
				{ title: 'Users', page: `user-management/users` },
				{ title: 'Create Training Center', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit Posting');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users', page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		let closingDate = new Date(this.closingDate);
		let openingDate = new Date(this.openingDate);

		this.postingForm = this.userFB.group({
			closingDate: [new Date(this.processDate(closingDate,false)), Validators.required],
			closingTime: [this.getTimeAsString(closingDate), Validators.required],
			openingDate: [new Date(this.processDate(openingDate,false)), Validators.required],
			openingTime: [this.getTimeAsString(openingDate), Validators.required],
			notificationMessage: [this.notificationMessage, Validators.required],
			jwt: [this.user.jwt],


		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	checkValue(value) {
		console.log(value)
	}

	getTimeAsString(date: Date) {
		return `${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") +
			date.getMinutes()}`;
	}


	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.postingForm.markAsPristine();
		this.postingForm.markAsUntouched();
		this.postingForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.postingForm.controls;
		/** check form */
		if (this.postingForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedCenter = this.prepareCenter();



		this.updateConfig(editedCenter, withBack);

	}

	/**
	 * Returns prepared data for save
	 */
	prepareCenter(): any {
		const controls = this.postingForm.controls;
		
		let openingDate = this.processDateTime(controls.openingDate.value, controls.openingTime.value)
		let openingTime = controls.openingTime.value
		let closingDate = this.processDateTime(controls.closingDate.value, controls.closingTime.value)
		let closingTime = controls.closingTime.value

	
		let _config = {
			openingDate: openingDate,
			closingDate: closingDate,
			notificationMessage: controls.notificationMessage.value,
			jwt: controls.jwt.value,
			id: this.user.id

		}

		return _config;
	}

	processDateTime(date, time): any {
		let processed = new Date(date).toISOString().split('T')[0]
		let day = +processed.split('-')[2];
		let month = processed.split('-')[1];
		let year = processed.split('-')[0];
		day += 1;
		const reconstructed = year + '/' + month + '/' + day;
		return reconstructed + ' ' + time;
	}
	processDate(date, add? :boolean): any {
		let processed = new Date(date).toISOString().split('T')[0]
		let day = +processed.split('-')[2];
		let month = processed.split('-')[1];
		let year = processed.split('-')[0];
		if(add){
			day += 1;

		}
		const reconstructed = year + '/' + month + '/' + day;
		return reconstructed;
	}

	compareDates(date1,date2){
		let splittedDate1 = date1.split('/');
		let splittedDate2 = date2.split('/');
		
		// compare year
		if(+splittedDate2[0] < +splittedDate1[0]){
			return false;
		}
        
		// comapre month 
		if((+splittedDate2[1] < +splittedDate1[1]) && (+splittedDate2[0] <= +splittedDate1[0])  ){
			return false;
		}


        // compare day
		if((+splittedDate2[2] < +splittedDate1[2]) && (+splittedDate2[1] <= +splittedDate1[1])  ){
			return false;
		}

	}

	convertTimetoMilliSeconds(time: string) {
		console.log('the time', time)
		let hour = time.split(':')[0];
		let minute = time.split(':')[1];
		let hoursToMiliSeconds = +hour * 60 * 60 * 1000;
		let minuteInMiliSeconds = +minute * 60 * 1000

		return (hoursToMiliSeconds + minuteInMiliSeconds);

	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addBatch(_posting: BatchConfig, withBack: boolean = false) {

		// this.vacancyService.postOfficer(_posting).subscribe(data=>{

		// 	const message = `New Posting successfully added.`;
		// 	this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
		// 	this.onCLose();

		// },error=>{
		//   let err = error.error
		//   alert(JSON.stringify(err))
		//   Swal.fire(
		//     'Oops! Posting Unsuccessful',
		//     err,
		//     'error'
		//     )
		// })

	}

	/**
	 * Update configuration
	 *
	 * @param _config: any
	 * @param withBack: boolean
	 */
	updateConfig(_config: any, withBack: boolean = false) {
        const controls = this.postingForm.controls;
		if(this.compareDates(this.processDate(controls.closingDate.value,true), this.processDate(controls.openingDate.value,true))=== false){
			Swal.fire({
				icon: 'error',
				title: 'Invalid Date Range',
				text: 'Portal opening date cannot be earlier than closing date!'
				
			  })
		  return ;
	  }
		this.blockUI.start('Updating configuration... Please wait')
		console.log(_config)
		this.vacancyService.updatePortalConfig(_config).subscribe(data => {
			this.blockUI.stop()
			const message = `Portal Configuration Successfully Updated.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose();

		}, error => {
			this.blockUI.stop()
			this.layoutUtilsService.showActionNotification(error.message, MessageType.Delete, 5000, true, true);

		})



	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Edit Portal Closing Configuration';
		if (!this.user || !this.user.id) {
			return result;
		}

		result = `Edit Portal Closing Configuration `;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getCitiesForState(stateId) {

		this.authService.getCitiesByStateId(stateId).subscribe(data => {
			this.cities = data
		})

	}

	onCLose() {
		this.reset()
		this.dialogRef.close()
	}

	getAllStates() {
		this.authService.getAllStates().subscribe(data => {
			this.states = data;
		})
	}
	getALLOfficers() {
		this.authService.getAllAllOfficers().subscribe(data => {
			this.officers = data;
		})
	}
	getAllCenters() {
		this.vacancyService.getAVailableVacancies(1).subscribe(data => {
			this.centers = data

		}

		)

	}

	getUSerType() {
		return this.vacancyService.getUserType()
	}
}
