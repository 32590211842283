// Angular
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { environment } from '../../../../../../environments/environment';



@Component({
	selector: 'kt-user-edit',
	templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit, OnDestroy {
	// Public properties
	user: User;
	userId$: Observable<number>;
	oldUser: User;
	fName:string;
	lName:string;
	oName:string;
	username:string;
	email:string;
	role:number;
	userType:string;
	id:number;
	status:string;
	@BlockUI() blockUI: NgBlockUI;
    selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	userForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];
	roles: any;
	baseUrl:string;
	passport:string;
	message :string;
	theImage:any;

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private userFB: FormBuilder,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
				   private store: Store<AppState>,
				   private authService: AuthService,
				   private dialogRef: MatDialogRef<UserEditComponent>,
				   private layoutConfigService: LayoutConfigService,
				   @Inject(MAT_DIALOG_DATA) data
				   ) { 
					  if(data){
						this.fName = data.firstName
						this.lName = data.lastName
						this.username = data.username
						this.email = data.email
						this.role = data.role
						this.status = data.status
						this.passport = data.passport					
						this.userType = data.userType
						this.oName = data.otherName
						this.id = data.id
						

					  }
					   
				   }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.baseUrl = environment.BASE_URL + "api/";
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;
						this.rolesSubject.next(this.user.roles);
						this.addressSubject.next(this.user.address);
						this.soicialNetworksSubject.next(this.user.socialNetworks);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
				this.user = new User();
				this.user.clear();
				this.rolesSubject.next(this.user.roles);
				this.addressSubject.next(this.user.address);
				this.soicialNetworksSubject.next(this.user.socialNetworks);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);

		//alert(JSON.stringify(this.user))
		this.getAllRoles()
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (!this.user.id) {
			this.subheaderService.setTitle('Create user');
			this.subheaderService.setBreadcrumbs([
				{ title: 'User Management', page: `user-management` },
				{ title: 'Users',  page: `user-management/users` },
				{ title: 'Create user', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit user');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users',  page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.user.firstName = this.fName
		this.user.role = this.role
		this.user.lastName = this.lName
		this.user.email = this.email
		this.user.username = this.username
		this.user.otherName = this.oName
		this.user.userType = this.userType
		this.user.id = this.id
		this.user.status = this.status
		this.user.passport = this.passport
		
		this.userForm = this.userFB.group({
			username: [this.user.username, Validators.required],
			role: [this.user.role, Validators.required],
		
			firstName: [this.user.firstName, Validators.required],
			lastName: [this.user.lastName, Validators.required],
			otherName: [this.user.otherName],
			email: [this.user.email, Validators.email],
			phone: [this.user.phone],
			status: [this.user.status,Validators.required],
			profileImage:[''],
			passport:[this.passport],
			userType:[this.user.userType, Validators.required],
			jwt:[this.user.jwt],
		
			
		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.userForm.markAsPristine();
  this.userForm.markAsUntouched();
  this.userForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.userForm.controls;
		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedUser = this.prepareUser();
  
		
		if (editedUser.id > 0) {
			this.updateUser(editedUser, withBack);
			return;
		}

		this.addUser(editedUser, withBack);
	}

	/**
	 * Returns prepared data for save
	 */
	prepareUser(): User {
		const controls = this.userForm.controls;
		const _user = new User();
		_user.clear();
		_user.firstName =  controls.firstName.value;
		_user.lastName =  controls.lastName.value;
		_user.otherName =  controls.otherName.value;
		_user.role = controls.role.value;
		_user.userType = controls.userType.value;
		_user.status = controls.status.value;
		_user.passport = controls.passport.value;
			
		// _user.pic = this.user.pic;
		_user.id = this.user.id;
		_user.username = controls.username.value;
		_user.email = controls.email.value;
	
	
		_user.phone = controls.phone.value;
	
		// _user.password = this.user.password;
		return _user;
	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addUser(_user: User, withBack: boolean = false) {
		this.blockUI.start('Creating User...Please Wait');

		this.store.dispatch(new UserOnServerCreated({ user: _user }));
		this.authService.InternalUserregister(_user).subscribe(data=>{
			this.blockUI.stop();
			const message = `New user successfully added.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose();

		},
		error=>{
			this.blockUI.stop();
			Swal.fire(
				error.error.message,
				'Kindly make the neccessay correction and try again ',
				'error'
				)

		})
	
	}
	onFileChange(event){
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			
			var mimeType = file.type;
		   // alert(mimeType)
		  if (mimeType.match(/image\/*/) == null) {
			Swal.fire(
				'Invalid type',
				'Only image (jpg,png) file  are supported ',
				'error'
				)
			return;
		  }
		  else
		   {
			  if(file.size/1024 > 100)
			  {
				Swal.fire(
					'Invalid size',
					'Image cannot be more than 100kb. the uploaded image is '+ file.size/1024 + 'kb in size ',
					'error'
					)
				return;
			  }
			  this.message = null;
			  
			  var image = new Image();
		  let that = this
		  var myReader:FileReader = new FileReader();
		  myReader.onloadend = function (loadEvent:any) {
			  image.src = loadEvent.target.result;
			  //that.userForm.get('profileImage').setValue(file)
            
	   
		  };
		  this.theImage = file
	   
		  myReader.readAsDataURL(file);
  
		let user = this.prepareUser();
		this.updateUserProfileImage(user)
		   }
	
		}
	}

	
	updateUserProfileImage(_user: User){
		this.blockUI.start('Prcocessing...');
				const formData = new FormData();
				formData.append('file', this.theImage);
					this.authService.uploadFile(formData).subscribe(data=>{
						_user.passport=data.message;
						
							  this.updateUser(_user)
							  this.blockUI.stop();
					})
					
    
	}
	
	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updateUser(_user: User, withBack: boolean = false) {
		// Update User
		// tslint:disable-next-line:prefer-const

		// const updatedUser: Update<User> = {
		// 	id: _user.id,
		// 	changes: _user
		// };
		//alert("updated user"+JSON.stringify(updatedUser))
		this.authService.InternalUserUpdate(_user).subscribe(data=>{

			const message = `user successfully updated.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose();

		})
		// this.store.dispatch(new UserUpdated( { partialUser: updatedUser, user: _user }));
		// const message = `User successfully has been saved.`;
		// this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
		// if (withBack) {
		// 	this.goBackWithId();
		// } else {
		// 	this.refreshUser(false);
		// }
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Create user';
		if (!this.user || !this.user.id) {
			return result;
		}

		result = `Edit user - ${this.user.fullname}`;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	onCLose(){
		this.reset()
		this.dialogRef.close()
	}

	getAllRoles(){
		this.authService.getAllRoles().subscribe(
			list=>{
				this.roles= list;
			
				
			}
			)

	}
}
