import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../core/auth';
import { AuthService } from '../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../core/center/center.model';
import { CenterService } from '../../../core/center/center.service';
import { Vacancy } from '../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import { VacancyEditComponent } from '../vacancy-management/vacancy-edit/vacancy-edit.component';
import { Officer } from '../../../core/auth/_models/officer.models';
import { Posting } from '../../../core/vacancy/posting.model';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { Bank } from '../../../core/vacancy/bank.model';

@Component({
  selector: 'kt-my-account-details',
  templateUrl: './my-account-details.component.html',
  styleUrls: ['./my-account-details.component.scss']
})
export class MyAccountDetailsComponent implements OnInit {

  accountDetails: any;
  constructor(private vacancyService : VacanciesService) { }

  ngOnInit() {
    this.getAccountDetails()
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}

getAccountDetails(){
  this.vacancyService.getAccountDetails().subscribe((data)=>{
    this.accountDetails = data[0];
   // alert(JSON.stringify(this.accountDetails))
   
   
  },
  error=>{
   
  })
}

}
