// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
const helper = new JwtHelperService();
const BASE_URL =environment.BASE_URL;
@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	profilePicture: any;
	profilePictureBase: any;
	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>,
		private router: Router

		) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
     
		this.user$ = helper.decodeToken(localStorage.getItem('token')).data;
		
		this.profilePictureBase = BASE_URL+"api/";
		
	}

	/**
	 * Log out
	 */
	logout() {
		localStorage.clear();
		this.store.dispatch(new Logout());
		this.router.navigateByUrl('auth/login').then(()=>{
			location.reload()

		}); // Main page

		
	}
}
