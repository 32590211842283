import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../../../core/center/center.model';
import { CenterService } from '../../../../../core/center/center.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
@Component({
  selector: 'kt-center-edit',
  templateUrl: './center-edit.component.html',
  styleUrls: ['./center-edit.component.scss']
})
export class CenterEditComponent implements OnInit, OnDestroy {
	// Public properties
  user: User;
  center: Center;
	userId$: Observable<number>;
	oldUser: User;
	cName:string;
	slogan:string;
	address:string;
	tel:string;
	email:string;
	role:number;
	userType:string;
	id:number;
	states:any;
	cities :any;
	stateId:number;
	cityId:number;
	city:string;
	category:string;
	status:string;
	@BlockUI() blockUI: NgBlockUI;

	selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	centerForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private userFB: FormBuilder,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
				   private store: Store<AppState>,
				   private authService: AuthService,
				   private centerService: CenterService,
				   private dialogRef: MatDialogRef<CenterEditComponent>,
				   private layoutConfigService: LayoutConfigService,
				 
				   @Inject(MAT_DIALOG_DATA) data
				   ) { 
					  if(data){
						  this.id = data.id;
						  this.cName = data.centerName;
						  this.tel= data.tel;
						  this.slogan = data.slogan
						  this.email =data.email
						  this.address = data.address
						  this.stateId = data.stateId
						  this.cityId = data.cityId
						  this.category = data.category
						  this.status = data.status
					
						

					  }
					   
				   }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;
						this.rolesSubject.next(this.user.roles);
						this.addressSubject.next(this.user.address);
						this.soicialNetworksSubject.next(this.user.socialNetworks);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
				this.user = new User();
				this.center = new Center();
				this.center.clear();
				this.user.clear();
				this.rolesSubject.next(this.user.roles);
				this.addressSubject.next(this.user.address);
				this.soicialNetworksSubject.next(this.user.socialNetworks);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);

		//alert(JSON.stringify(this.user))
	this.getAllStates()
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (!this.user.id) {
			this.subheaderService.setTitle('Create Training Center');
			this.subheaderService.setBreadcrumbs([
				{ title: 'User Management', page: `user-management` },
				{ title: 'Users',  page: `user-management/users` },
				{ title: 'Create Training Center', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit Training Center');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users',  page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.center.centerName = this.cName
		this.center.slogan = this.slogan
		 this.center.tel = this.tel
		 this.center.email = this.email
		 this.center.address = this.address
		 this.center.stateId = this.stateId
		 this.center.cityId = this.cityId
		 this.center.category = this.category
		 this.center.status = this.status
		this.getCitiesForState(this.stateId)
		
		this.centerForm = this.userFB.group({
	
			centerName: [this.center.centerName, Validators.required],
			slogan: [this.center.slogan, Validators.required],
			category: [this.center.category, Validators.required],
			tel: [this.center.tel, Validators.required],
			email: [this.center.email, Validators.required],
			address: [this.center.address, Validators.required],
			stateId: [this.center.stateId, Validators.required],
			cityId: [this.center.cityId, Validators.required],
			status: [this.center.status, Validators.required],

			
			jwt:[this.user.jwt],
		
			
		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/center-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.centerForm.markAsPristine();
  this.centerForm.markAsUntouched();
  this.centerForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.centerForm.controls;
		/** check form */
		if (this.centerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedCenter = this.prepareCenter();
  
		
		if (editedCenter.id > 0) {
			this.updateCenter(editedCenter, withBack);
			return;
		}

		this.addCenter(editedCenter, withBack);
	}

	/**
	 * Returns prepared data for save
	 */
	prepareCenter(): Center {
		const controls = this.centerForm.controls;
		const _center = new Center();
		_center.clear();
		
		
		_center.id = this.id;
		_center.centerName = controls.centerName.value;
		_center.category = controls.category.value;
		_center.slogan = controls.slogan.value;
		_center.tel = controls.tel.value;
		_center.address = controls.address.value;
		_center.stateId = controls.stateId.value;
		_center.cityId = controls.cityId.value;
		_center.status = controls.status.value;
		
		_center.email = controls.email.value;
	
	
		
	
	
		return _center;
	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addCenter(_center: Center, withBack: boolean = false) {
		this.blockUI.start('Creating Centre...');
		this.centerService.postCenter(_center).subscribe(data=>{
            this.blockUI.stop()
			const message = `New centre successfully added.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose(data.data);

		}, error=>{
			this.blockUI.stop();
			Swal.fire(
				error.error.message,
				'Kindly make the neccessay correction and try again ',
				'error'
				)
		})
	
	}

	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updateCenter(_center: Center, withBack: boolean = false) {
		this.blockUI.start('Updating Centre...');

		this.centerService.UpdateCenter(_center).subscribe(data=>{
			this.blockUI.stop();
			const message = `centre successfully updated.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose(data.data);

		},error=>{
			this.blockUI.stop();
			Swal.fire(
				error.error.message,
				'Kindly make the neccessay correction and try again ',
				'error'
				)
		})
		
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Create Training Centre';
		if (!this.user || !this.center.id) {
			return result;
		}

		result = `Edit Training Centre - ${this.center.centerName}`;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getCitiesForState(stateId){
		
		this.authService.getCitiesByStateId(stateId).subscribe(data=>{
			this.cities = data
		})

	}
	getAllCenters(){
	  return this.centerService.getALLCenters()

	}

	onCLose(data){
		this.reset()
		this.dialogRef.close(data)
	}

	getAllStates(){
     this.authService.getAllStates().subscribe(data=>{
		 this.states = data;
	 })
	}
}
