import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Center } from '../../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../../core/center/center.service';
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/utils/layout-utils.service';
import { CenterEditComponent } from '../../training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesService } from '../../../../core/vacancy/vacancies.service';
import { Vacancy } from '../../../../core/vacancy/vacancy.model';
import { VacancyEditComponent } from '../vacancy-edit/vacancy-edit.component';
import * as XLSX from 'xlsx';
import { VacancyManualEditComponent } from '../vacancy-manual-edit/vacancy-manual-edit.component';


@Component({
	selector: 'kt-vacancies',
	templateUrl: './vacancies.component.html',
	styleUrls: ['./vacancies.component.scss']
})
export class VacanciesComponent implements OnInit {
	searchKey: string;
	availableVacacncies: any;
	exportData: any;
	// Table fields

	listData: MatTableDataSource<Vacancy>;
	displayedColumns = ['id', 'centerName',  'type', 'approvedTotal', 'quotaInUse', 'mdcn_left', 'officer_left', 'center_left'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	// Subscriptions
	private subscriptions: Subscription[] = [];

	constructor(
		private centerService: CenterService,
		private vacancyService: VacanciesService,
		private layoutUtilsService: LayoutUtilsService,
		private dialog: MatDialog,
		private _changeDetectorRef: ChangeDetectorRef,
	) { }

	ngOnInit() {

		// load allusers 
		this.getAllVacancies();
		this.getAvailableVacancies();

	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */


	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}



	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {

	}
	getAvailableVacancies() {
		this.vacancyService.getAvailableVacancy().subscribe(
			data => {
				this.availableVacacncies = data;



			}
		)

	}

	getAllVacancies() {
		this.vacancyService.getALLVacancies().subscribe(
			list => {
				let array = list.map(item => {
					return item
				})

				this.exportData = array;
				this.listData = new MatTableDataSource(array)

				this.listData.sort = this.sort
				this.listData.paginator = this.paginator

				  // Mark for check
				  this._changeDetectorRef.markForCheck();




			}
		)

	}
	exportExcel() {
		const workSheet = XLSX.utils.json_to_sheet(this.exportData, { header: ['Vacancy Management'] });

		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Vacancy Management');
		//XLSX.utils.book_append_sheet(workBook, workSheet2, 'Clearance_Stats');
		var fileName = "Vacancy_Management" + Date.now() + ".xlsx";
		XLSX.writeFile(workBook, fileName);
	}
	applyFilter() {

		this.listData.filter = this.searchKey.trim().toLowerCase();
	}

	onCreate() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		this.dialog.open(VacancyEditComponent, dialogConfig)
	}
	onEdit(center, id) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		dialogConfig.data = center;
		this.dialog.open(VacancyManualEditComponent, dialogConfig).afterClosed().subscribe(
			(data) =>{
			this.getAllVacancies();
		})
		//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
	}

	onAdd(center, id) {

		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		dialogConfig.data = center;
		this.dialog.open(VacancyEditComponent, dialogConfig).afterClosed().subscribe(
			(data) =>{
			this.getAllVacancies();
		})
		//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
	}
}
