// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MAT_DATE_LOCALE
	
	

} from '@angular/material';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import { MyPostingComponent } from './my-posting/my-posting.component';
import { MyPaymentsComponent } from './my-payments/my-payments.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyEmailsComponent } from './my-emails/my-emails.component';
import { TrainingCenterManagementComponent } from './training-center-management/training-center-management.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CenterEditComponent } from './training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesComponent } from './vacancy-management/vacancies/vacancies.component';
import { VacancyEditComponent } from './vacancy-management/vacancy-edit/vacancy-edit.component';
import { PostingsComponent } from './officer-management/postings/postings/postings.component';
import { EditPostingComponent } from './officer-management/postings/edit-posting/edit-posting.component';
import { RepostOfficerComponent } from './officer-management/postings/repost-officer/repost-officer.component';
import { CenterStaffComponent } from './training-center-management/center-staff/center-staff.component';
import { EditCenterStaffComponent } from './training-center-management/edit-center-staff/edit-center-staff.component';
import { MyAccountDetailsComponent } from './my-account-details/my-account-details.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { MonthlyClearanceComponent } from './officer-management/clearance/monthly-clearance/monthly-clearance.component';
import { MdcnMonthlyClearanceComponent } from './officer-management/clearance/mdcn-monthly-clearance/mdcn-monthly-clearance.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { PostingCongifurationComponent } from './posting-congifuration/posting-congifuration.component';
import { EditPostingCongifurationComponent } from './posting-congifuration/edit-posting-congifuration/edit-posting-congifuration.component';
import { EditPasswordComponent } from './my-profile/edit-password/edit-password.component';
import { OfficerDetailsComponent } from './officer-management/postings/officer-details/officer-details.component';
import { OfficersAccountDetailsComponent } from './officer-management/officers-account-details/officers-account-details.component';
import { BlockUIModule } from 'ng-block-ui';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { PostingHistoryComponent } from './officer-management/postings/posting-history/posting-history.component';
import { PostingResetHistoryComponent } from './officer-management/postings/posting-reset-history/posting-reset-history.component';
import { HouseOfficersComponent } from './officer-management/house-officers/house-officers.component';
import { BatchConfigurationComponent } from './batch-configuration/batch-configuration.component';
import { EditBatchConfigurationComponent } from './batch-configuration/edit-batch-configuration/edit-batch-configuration.component';
import { HouseOfficersAwaitingPostingComponent } from './officer-management/house-officers-awaiting-posting/house-officers-awaiting-posting.component';
import { HouseOfficersAwaitingAcceptanceComponent } from './officer-management/house-officers-awaiting-acceptance/house-officers-awaiting-acceptance.component';
import { HouseOfficersUnderTrainingComponent } from './officer-management/house-officers-under-training/house-officers-under-training.component';
import { HouseOfficersGraduatedComponent } from './officer-management/house-officers-graduated/house-officers-graduated.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackManagementComponent } from './feedback-management/feedback-management.component';
import { FeedbackReceiverComponent } from './feedback-receiver/feedback-receiver.component';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EditAccountDetailsComponent } from './officer-management/officer-account-details/edit-account-details/edit-account-details.component'; // <- import PdfViewerModule
import { NgpSortModule } from 'ngp-sort-pipe';
import { BatchManagementComponent } from './batch-management/batch-management.component';
import { EditBachComponent } from './batch-management/edit-bach/edit-bach.component';
import { ReplyFeedbackComponent } from './reply-feedback/reply-feedback.component';
import { ReplyFeedbackThreadComponent } from './reply-feedback-thread/reply-feedback-thread.component';
import { VacancyManualEditComponent } from './vacancy-management/vacancy-manual-edit/vacancy-manual-edit.component';
import { PortalOpeningConfigurationComponent } from './portal-opening-config/portal-opening-configuration.component';
import { EditPortalConfigurationComponent } from './portal-opening-config/edit-portal-configuration/edit-portal-configuration.component';
import { HouseOfficersResignedAndOwingComponent } from './officer-management/house-officers-resigned-and-owing/house-officers-resigned-and-owing.component';
import { HouseOfficersResignedAndNotOwingComponent } from './officer-management/house-officers-resigned-and-not-owing/house-officers-resigned-and-owing.component';
import { HouseOfficersResignedAndPaidUpComponent } from './officer-management/house-officers-resigned-owing-paid-up/house-officers-resigned-and-owing.component';
import { DraftVacanciesComponent } from './vacancy-management/vacancy-draft/vacancies.component';
import { ApproveVacanciesComponent } from './vacancy-management/approve-vacancy/vacancies.component';

@NgModule({
	declarations: [MyPageComponent, MyPostingComponent, MyPaymentsComponent,
		 MyProfileComponent, MyEmailsComponent, TrainingCenterManagementComponent, 
		 CenterEditComponent, VacanciesComponent, VacancyEditComponent, PostingsComponent,
		  EditPostingComponent, RepostOfficerComponent, CenterStaffComponent, EditCenterStaffComponent,
		   MyAccountDetailsComponent, AuditTrailComponent, MonthlyClearanceComponent, 
		   MdcnMonthlyClearanceComponent, PostingCongifurationComponent,EditPostingCongifurationComponent, 
		   EditPasswordComponent, OfficerDetailsComponent, OfficersAccountDetailsComponent, 
		   PostingHistoryComponent, PostingResetHistoryComponent, HouseOfficersComponent, 
		   BatchConfigurationComponent, EditBatchConfigurationComponent, HouseOfficersAwaitingPostingComponent,
		    HouseOfficersAwaitingAcceptanceComponent, HouseOfficersUnderTrainingComponent,
			 HouseOfficersGraduatedComponent, FeedbackComponent, FeedbackManagementComponent,
			  FeedbackReceiverComponent, EditAccountDetailsComponent, BatchManagementComponent,
			 EditBachComponent, ReplyFeedbackComponent, ReplyFeedbackThreadComponent,VacancyManualEditComponent,
			  PortalOpeningConfigurationComponent,EditPortalConfigurationComponent,
			HouseOfficersResignedAndOwingComponent,HouseOfficersResignedAndNotOwingComponent,HouseOfficersResignedAndPaidUpComponent, DraftVacanciesComponent, ApproveVacanciesComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
        MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		ContentLoaderModule,
		BlockUIModule.forRoot(),
		//AutocompleteLibModule,
		MatTableExporterModule,
		BrowserModule,
	//	PdfViewerModule,
		NgpSortModule
		

		
	],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class PagesModule {
}
