// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
// Lodash
import { filter, some, find, each } from 'lodash';
// Environment
import { environment } from '../../../../environments/environment';
// CRUD
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
// Models
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { Login } from '../_models/login.model';
import { JwtHelperService } from "@auth0/angular-jwt";
import * as sha512 from 'js-sha512';
import { MDCNAPI } from '../_models/mdcn-api.model';



const MDCN_API = environment.MDCN_API;
const BASE_URL =environment.BASE_URL;
const LOGIN_URL =BASE_URL+'api/login.php'
const API_REGISTER_URL = BASE_URL+ 'api/new_register';
const API_CHECK_OFFICER = BASE_URL+ 'api/checkofficer';
const API_CHECK_OFFICER_BACKLOG = BASE_URL+ 'api/checkofficerbacklog';
const API_REQUEST_PASSWORD = BASE_URL+ 'api/checkpassworduser';
const API_RESET_PASSWORD = BASE_URL+ 'api/resetpassword';
const API_USERS_URL = BASE_URL+ 'api/users';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
const helper = new JwtHelperService();
const API_UPLOAD_URL = BASE_URL +'api/upload';
@Injectable()
export class AuthService {
    constructor(private http: HttpClient,
                private httpUtils: HttpUtilsService) { }

    // Authentication/Authorization
    login(email: string, password: string): Observable<any> {
        if (!email || !password) {
            return of(null);
        }
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        let user ={
            "email" : email,
            "password" : password
        }

        return this.http.post<Login>(LOGIN_URL, user, { headers: httpHeaders })
            // .pipe(
            //     map((res: Login) => {
               
            //         if (!res) {
            //            return null;
            //              }
                
            //         return res;
            //     }),
            //     catchError(err => {
                     
            //         return Observable.throw(err);
            //     })
            // );
    //     return  this.getAllUsers().pipe(
    //         map((result: User[]) => {
    //             if (result.length <= 0) {
    //                 return null;
    //             }

    //             const user = find(result, (item: User) => {
    //                 return (item.email.toLowerCase() === email.toLowerCase() && item.password === password);
    //             });

    //             if (!user) {
    //                 return null;
    //             }

    //             user.password = undefined;
    //             return user;
    //         })
    //    );

    }

    register(user: User): Observable<any> {
        user.roles = [2]; // Manager
        user.accessToken = 'access-token-' + Math.random();
        user.refreshToken = 'access-token-' + Math.random();
     

        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_REGISTER_URL, user, { headers: httpHeaders })
    }


    getDoctorStatus(folio,dateOfIssue):Observable<any>{
        let tohash = folio+87452832+65443623
        let req = {
           
             "folio": folio,
             "dateOfIssue":dateOfIssue,
             "hash": sha512.sha512(tohash)
             }
             const userToken = localStorage.getItem('token');
             const httpHeaders = new HttpHeaders();

             let headers: HttpHeaders = new HttpHeaders();
             headers = headers.append('Accept', 'application/json');
             headers = headers.append('merchantId', '65443623');

             httpHeaders.set('merchantId', '65443623');
             return this.http.post(MDCN_API, req, { headers: headers });
     }

     checkOfficer(folio):Observable<any>{
        let tohash = folio+87452832+65443623
        let req = {
           
             "folioNumber": folio,
          
             }
             const userToken = localStorage.getItem('token');
             const httpHeaders = new HttpHeaders();

             let headers: HttpHeaders = new HttpHeaders();
             headers = headers.append('Accept', 'application/json');
            // headers = headers.append('merchantId', '65443623');

           //  httpHeaders.set('merchantId', '65443623');
             return this.http.post(API_CHECK_OFFICER, req);
     }

     checkBackLogOfficer(folio):Observable<any>{
      
        let req = {
           
             "folioNumber": folio,
          
             }
             const userToken = localStorage.getItem('token');
             const httpHeaders = new HttpHeaders();

             let headers: HttpHeaders = new HttpHeaders();
             headers = headers.append('Accept', 'application/json');
            // headers = headers.append('merchantId', '65443623');

           //  httpHeaders.set('merchantId', '65443623');
             return this.http.post(API_CHECK_OFFICER_BACKLOG
                , req);
     }

    requestPassword(email: string): Observable<any> {
       let data = {
            "email" :  email
        }
    	return this.http.post(API_REQUEST_PASSWORD,data);
        
    }
    resetPassword(password: string, requestNumber:string): Observable<any> {
        let data = {
             "password" :  password,
             "requestNumber": requestNumber
         }
         return this.http.put(API_RESET_PASSWORD,data);
         
     }

    

    getUserByToken(): Observable<User> {
       // const userToken = localStorage.getItem(environment.authTokenKey);
       const userToken = localStorage.getItem('token');
        if (!userToken) {
            return of(null);
        }

        var decoded = helper.decodeToken(userToken);
console.log(decoded);
//alert(JSON.stringify(decoded.data))
return decoded.data;
        // return this.getAllUsers().pipe(
        //     map((result: User[]) => {
        //         if (result.length <= 0) {
        //             return null;
        //         }

        //         const user = find(result, (item: User) => {
        //             return (item.accessToken === userToken.toString());
        //         });

        //         if (!user) {
        //             return null;
        //         }

        //         user.password = undefined;
        //         return user;
        //     })
        // );
    }

    // Users

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
        const httpHeaders = new HttpHeaders();
        // Note: Add headers if needed (tokens/bearer)
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders});
    }

    uploadFile(formData): Observable<any> {
    	
        return this.http.post(API_UPLOAD_URL,formData, );
 }

    // READ
    getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(BASE_URL+API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        if (!userId) {
            return of(null);
        }

		      return this.http.get<User>(API_USERS_URL + `/${userId}`);
    }

    // DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${API_USERS_URL}/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_USERS_URL, _user, { headers: httpHeaders }).pipe(
            catchError(err => {
                return of(null);
            })
        );
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// This code imitates server calls
		return this.getAllUsers().pipe(
			mergeMap((response: User[]) => {
				const result = this.httpUtils.baseFilter(response, queryParams, []);
				return of(result);
			})
		);
	}

    // Permissions
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        const allRolesRequest = this.http.get<Permission[]>(API_PERMISSION_URL);
        const roleRequest = roleId ? this.getRoleById(roleId) : of(null);
        return forkJoin(allRolesRequest, roleRequest).pipe(
			map(res => {
				const _allPermissions: Permission[] = res[0];
    const _role: Role = res[1];
    if (!_allPermissions || _allPermissions.length === 0) {
                    return [];
                }

    const _rolePermission = _role ? _role.permissions : [];
    const result: Permission[] = this.getRolePermissionsTree(_allPermissions, _rolePermission);
    return result;
            })
        );
    }

    private getRolePermissionsTree(_allPermission: Permission[] = [], _rolePermissionIds: number[] = []): Permission[] {
        const result: Permission[] = [];
        const _root: Permission[] = filter(_allPermission, (item: Permission) => !item.parentId);
        each(_root, (_rootItem: Permission) => {
            _rootItem._children = [];
            _rootItem._children = this.collectChildrenPermission(_allPermission, _rootItem.id, _rolePermissionIds);
            _rootItem.isSelected = (some(_rolePermissionIds, (id: number) => id === _rootItem.id));
            result.push(_rootItem);
        });
        return result;
    }

    private collectChildrenPermission(_allPermission: Permission[] = [],
                                      _parentId: number, _rolePermissionIds: number[]  = []): Permission[] {
        const result: Permission[] = [];
        const _children: Permission[] = filter(_allPermission, (item: Permission) => item.parentId === _parentId);
        if (_children.length === 0) {
            return result;
        }

        each(_children, (_childItem: Permission) => {
            _childItem._children = [];
            _childItem._children = this.collectChildrenPermission(_allPermission, _childItem.id, _rolePermissionIds);
            _childItem.isSelected = (some(_rolePermissionIds, (id: number) => id === _childItem.id));
            result.push(_childItem);
        });
        return result;
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// This code imitates server calls
		return this.http.get<Role[]>(API_ROLES_URL).pipe(
			mergeMap(res => {
				const result = this.httpUtils.baseFilter(res, queryParams, []);
				return of(result);
			})
		);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.getAllUsers().pipe(
            map((users: User[]) => {
                if (some(users, (user: User) => some(user.roles, (_roleId: number) => _roleId === roleId))) {
                    return true;
                }

                return false;
            })
        );
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
