import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../../../core/center/center.model';
import { CenterService } from '../../../../../core/center/center.service';
import { Vacancy } from '../../../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../../../core/vacancy/vacancies.service';
import { VacancyEditComponent } from '../../../vacancy-management/vacancy-edit/vacancy-edit.component';
import { Officer } from '../../../../../core/auth/_models/officer.models';
import { Posting } from '../../../../../core/vacancy/posting.model';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Bank } from '../../../../../core/vacancy/bank.model';

@Component({
  selector: 'kt-edit-account-details',
  templateUrl: './edit-account-details.component.html',
  styleUrls: ['./edit-account-details.component.scss']
})
export class EditAccountDetailsComponent implements OnInit {

 	// Public properties
   user: User;
   posting : Posting;
   trainingType: number;
   center: Center;
   vacancy: Vacancy;
   officer: Officer;
   userId$: Observable<number>;
   officerId:number;
   oldUser: User;
   cName:string;
   slogan:string;
   address:string;
   tel:string;
   email:string;
   role:number;
   userType:string;
   id:number;
   states:any;
   officers:any;
   cities :any;
   stateId:number;
   cityId:number;
   city:string;
   centers:any;
   selectrdTrainingType:any;
   typeId:any;
   type:any;
   banks:any;
   bankName:string;
   accountNumber:any;
   bvn:any;
   folioNumber:string;
   firstName:string;
   lastName:string;
   centerName:string;

   types = [
     {value: '1', viewValue: 'Medical Training'},
     {value: '2', viewValue: 'Dental Training'}
   
   ];
   selectedFood1: string;
   @BlockUI() blockUI: NgBlockUI;
 
 
   selectedTab = 0;
   loading$: Observable<boolean>;
   rolesSubject = new BehaviorSubject<number[]>([]);
   addressSubject = new BehaviorSubject<Address>(new Address());
   soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
   postingForm: FormGroup;
   hasFormErrors = false;
   // Private properties
   private subscriptions: Subscription[] = [];
 
   /**
    * Component constructor
    *
    * @param activatedRoute: ActivatedRoute
    * @param router: Router
    * @param userFB: FormBuilder
    * @param subheaderService: SubheaderService
    * @param layoutUtilsService: LayoutUtilsService
    * @param store: Store<AppState>
    * @param layoutConfigService: LayoutConfigService
    */
   constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userFB: FormBuilder,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
            private store: Store<AppState>,
            private authService: AuthService,
            private centerService: CenterService,
            private vacancyService: VacanciesService,
            private dialogRef: MatDialogRef<EditAccountDetailsComponent>,
            private layoutConfigService: LayoutConfigService,
          
            @Inject(MAT_DIALOG_DATA) data
            ) { 
             if(data){
               this.id = data.id;
               this.cName = data.centerName;
             
               this.type = data.type
               this.bankName = data.bankName
               this.bvn = data.bvn
               this.accountNumber = data.accountNumber
               this.folioNumber = data.folioNumber
               this.firstName = data.firstName
               this.lastName = data.lastName
               this.userId$ = data.userId
               this.officerId = data.officer
               this.centerName = data.centerName

              
             
             }
              
            }
 
   
   ngOnInit() {
     this.loading$ = this.store.pipe(select(selectUsersActionLoading));
     this.getAllBanks();
     const routeSubscription =  this.activatedRoute.params.subscribe(params => {
       const id = params.id;
       if (id && id > 0) {
         this.store.pipe(select(selectUserById(id))).subscribe(res => {
           if (res) {
             this.user = res;
             this.rolesSubject.next(this.user.roles);
             this.addressSubject.next(this.user.address);
             this.soicialNetworksSubject.next(this.user.socialNetworks);
             this.oldUser = Object.assign({}, this.user);
             this.initUser();
           }
         });
       } else {
         this.posting = new Posting();
         this.user = new User();
         this.center = new Center();
         this.vacancy = new Vacancy();
         this.vacancy.clear();
         this.center.clear();
         this.posting.clear();
         this.user.clear();
         this.rolesSubject.next(this.user.roles);
         this.addressSubject.next(this.user.address);
         this.soicialNetworksSubject.next(this.user.socialNetworks);
         this.oldUser = Object.assign({}, this.user);
         this.initUser();
       }
     });
     this.subscriptions.push(routeSubscription);
         this.userType= this.getUSerType();
   }
 
   ngOnDestroy() {
     this.subscriptions.forEach(sb => sb.unsubscribe());
   }
   
   onTrainingTypeChanged(){
     
     const controls = this.postingForm.controls;
   
     this.getAllCenters(controls.trainingType.value)
   }
 
   
   initUser() {
     this.createForm();
     if (!this.user.id) {
       this.subheaderService.setTitle('Account Details');
       this.subheaderService.setBreadcrumbs([
         { title: 'User Management', page: `user-management` },
         { title: 'Users',  page: `user-management/users` },
         { title: 'Create Training Center', page: `user-management/users/add` }
       ]);
       return;
     }
     this.subheaderService.setTitle('Account Details');
     this.subheaderService.setBreadcrumbs([
       { title: 'User Management', page: `user-management` },
       { title: 'Users',  page: `user-management/users` },
       { title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
     ]);
   }
 
   
   createForm() {
   
 
     this.postingForm = this.userFB.group({
   
      accountNumber: [this.accountNumber, Validators.compose([
				Validators.required,
				Validators.pattern('^[0-9]+$'),			
				Validators.minLength(10),
				Validators.maxLength(10)
			]),
		
			],
			bvn: [this.bvn, Validators.compose([
				Validators.required,
				Validators.pattern('^[0-9]+$'),			
                Validators.minLength(11),
				Validators.maxLength(11)
			]),
			
			
			],
			bankName: [this.bankName, Validators.compose([
				Validators.required,
			
				Validators.minLength(3),
				Validators.maxLength(40)
			])],
			
     
       jwt:[this.user.jwt],
     
       
     });
 
     
   }
 
 
   goBackWithId() {
     const url = `/user-management/users`;
     this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
   }
 
   refreshUser(isNew: boolean = false, id = 0) {
     let url = this.router.url;
     if (!isNew) {
       this.router.navigate([url], { relativeTo: this.activatedRoute });
       return;
     }
 
     url = `/user-management/users/edit/${id}`;
     this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
   }
 
   /**
    * Reset
    */
   reset() {
     this.user = Object.assign({}, this.oldUser);
     this.createForm();
     this.hasFormErrors = false;
     this.postingForm.markAsPristine();
   this.postingForm.markAsUntouched();
   this.postingForm.updateValueAndValidity();
   }
 
   /**
    * Save data
    *
    * @param withBack: boolean
    */
   onSumbit(withBack: boolean = false) {
     this.hasFormErrors = false;
     const controls = this.postingForm.controls;
     /** check form */
     if (this.postingForm.invalid) {
       Object.keys(controls).forEach(controlName =>
         controls[controlName].markAsTouched()
       );
 
       this.hasFormErrors = true;
       this.selectedTab = 0;
       return;
     }
 
     const editedBank = this.prepareBank();
     
       this.updateBank(editedBank, withBack);
    //return;
    
 
    // this.addPosting(editedCenter, withBack);
   }
 
   /**
    * Returns prepared data for save
    */
   prepareBank(): Bank {
     const controls = this.postingForm.controls;
     const _bank = new Bank();
     _bank.clear();	
     _bank.bvn =  controls.bvn.value;
   _bank.centerName = this.centerName;
   _bank.accountNumber= controls.accountNumber.value;
   _bank.bankName = controls.bankName.value;
   _bank.officer  = this.officerId;
   _bank.userId = this.userId$
  
     return _bank;
   }
 
   
   addBank(_posting: Posting, withBack: boolean = false) {
     this.blockUI.start('Posting...');
     
     this.vacancyService.postOfficer(_posting).subscribe(data=>{
             this.blockUI.stop()
       const message = `New Posting successfully added.`;
       this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
       this.onCLose();
 
     },error=>{
       this.blockUI.stop()
       let err = error.error.message
       //alert(JSON.stringify(err))
       Swal.fire(
         'Oops! Posting Unsuccessful',
         err,
         'error'
         )
     })
   
   }
 
   
   updateBank(_bank: Bank, withBack: boolean = false) {
     
      this.vacancyService.updateAccountDetails(_bank).subscribe(data=>{
 
     	const message = `Account successfully updated.`;
     	this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
     	this.onCLose();
 
     })
     
   }
 
   /**
    * Returns component title
    */
   getComponentTitle() {
     let result = 'Edit Account Details';
     if (!this.user || !this.user.id) {
       return result;
     }
 
     result = `  - ${this.vacancy.centerName}`;
     return result;
   }
 
   /**
    * Close Alert
    *
    * @param $event: Event
    */
   onAlertClose($event) {
     this.hasFormErrors = false;
   }
   getCitiesForState(stateId){
     
     this.authService.getCitiesByStateId(stateId).subscribe(data=>{
       this.cities = data
     })
 
   }
 
   onCLose(){
     this.reset()
     this.dialogRef.close()
   }
 
   getAllBatches(){
      this.authService.getAllBatches().subscribe(data=>{
      this.states = data;
    })
   }
   getALLOfficers(){
     this.authService.getAllAllOfficers().subscribe(data=>{
       this.officers = data;
     })
   }
   getOfficerByFolio(){
   const controls = this.postingForm.controls;
   this.blockUI.start('Fetching Officer Details...');
     this.authService.getOfficerByFolioNumber(controls.folioNumber.value).subscribe(data=>{
       this.blockUI.stop()
 
       this.officer = data[0];
      
 
     },error=>{
     this.blockUI.stop()
     let err = error.error.message
     //alert(JSON.stringify(err))
     Swal.fire(
       'Oops! Record not found',
       err,
       'error'
       )
 
     })
   }
   getAllCenters(type){
     this.blockUI.start('Fetching vacancies for selected training type... ')
     this.vacancyService.getAVailableVacancies(type).subscribe(data=>{
       this.blockUI.stop()
       this.centers = data
     },error=>{
     this.blockUI.stop()
     Swal.fire(
       'No vacancy for the selected training ',
       'If you think this is wrong, try it again later',
       'error'
       )
   }
     
       )
 
   }
   
   getUSerType(){
     return this.vacancyService.getUserType()
   }
   getAllBanks(){

		this.banks = [
		 
		 
		 
		 
		  {
			"name" : "Zenith Bank PLC"
		  },
		 
		 
		 
		  
		  {
			"name" : "Jaiz Bank LTD"
		  },
		 
		 
		  
		 
		 
		  {
			"name" : "Stanbic IBTC Bank PLC"
		  },
		 
		  
		  {
			"name" : "Oceanic Bank PLC"
		  },
		  {
			"name" : "Access Bank PLC"
		  },
		 
		
		  
		  {
			"name" : "United Bank for Africa PLC"
		  },
		 
		  {
			"name" : "Fidelity Bank PLC"
		  },
		  {
			"name" : "Sterling Bank PLC"
		  },
		 
		  
		  {
			"name" : "WEMA Bank Plc"
		  },
		  
		 
		  
		  {
			"name" : "First City Monument Bank Plc"
		  },

		  {
			"name" : "Diamond Bank Plc"
		  },
		 
		 
		 
		  {
			"name" : "Unity Bank LTD"
		  },
		  {
			"name" : "Standard Bank (South Africa)"
		  },
		  {
			"name" : "Keystone Bank LTD."
		  },
		  
		 
		  {
			"name" : "STANDARD CHARTERED BANK PLC"
		  },
		 
		  
		  {
			"name" : "Mainstreet Bank Ltd."
		  },
		  
		 
		 
		  {
			"name" : "Guaranty Trust Bank Plc"
		  },
		 
		 
		  {
			"name" : "Ecobank Nig. Ltd."
		  },
		 
		  
		  
		  {
			"name" : "SKYE BANK LTD."
		  },
		 
		  
		  {
			"name" : "Heritage Bank Ltd."
		  },
		  {
			"name" : "Union Bank Nigeria PLC"
		  },
		  
		 
		 
		 
		  {
			"name" : "Enterprise Bank Ltd."
		  },
		 
		  
		 
		 
		  
		  {
			"name" : "First Bank of Nigeria Plc"
		  },
		 
		 
		 
		  
		  {
			"name" : "HSBC Bank Plc"
		  }
		 
		 
		 
		  
		  
		  
		 
    ]
  
		
			
				
	
    }
    
    /**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.postingForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	
 
}
