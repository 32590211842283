export class BatchConfig {
    id: number;
    batchId: number;
    batchName:string;
  
    jwt:string;
   
    
    clear(): void {
        this.id = undefined;
        this.batchId = undefined;
        this.batchName = undefined;
        
        this.jwt = localStorage.getItem('token')
       
       
    }



}