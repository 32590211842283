import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Layout
import { LayoutUtilsService, MessageType } from '../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../core/auth';
import { AuthService } from '../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../core/center/center.model';
import { CenterService } from '../../../core/center/center.service';
import { Vacancy } from '../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'kt-reply-feedback',
  templateUrl: './reply-feedback.component.html',
  styleUrls: ['./reply-feedback.component.scss']
})
export class ReplyFeedbackComponent implements OnInit {

  user: User;
  center: Center;
  vacancy: Vacancy;
	userId$: Observable<number>;
	oldUser: User;
	cName:string;
	slogan:string;
	address:string;
	tel:string;
	email:string;
	role:number;
	userType:string;
	id:number;
  states:any;
  officers:any;
	cities :any;
	stateId:number;
	cityId:number;
  city:string;
  centers:any;
  mdcnQuota:any;
  officerQuota:any;
  centerQuota:any;
  currentBatch :any;
  batchName:string;
  feedbackMessage:string;
  @BlockUI() blockUI: NgBlockUI;
  feedbackId:any;
  message:any;


	selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	feedbackForm: FormGroup;
	hasFormErrors = false;
  ReplyMessage:string;
	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private fb: FormBuilder,
		           private layoutUtilsService: LayoutUtilsService,
				   private authService: AuthService,
           private centerService: CenterService,
           private vacancyService: VacanciesService,
				   private dialogRef: MatDialogRef<ReplyFeedbackComponent>,
           private feedbackService:VacanciesService,
				 
				   @Inject(MAT_DIALOG_DATA) data
				   ) { 
					  if(data){
              
                          this.id = data.id;
                          this.feedbackMessage = data.message
                          this.feedbackId = data.id;
          
          
					  }
					   
				   }


	ngOnInit() {

  this.createForm();
  this.userType= this.getUSerType();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}


	
	createForm() {
	
		this.feedbackForm = this.fb.group({
			message:['',Validators.required]	
		});
	}


	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.feedbackForm.markAsPristine();
  this.feedbackForm.markAsUntouched();
  this.feedbackForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
   onSubmit() {
		this.hasFormErrors = false;
		const controls = this.feedbackForm.controls;
		/** check form */
		if (this.feedbackForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const replyData = this.prepareReply();
  
		
	

		this.SendReply(replyData);
	}

	/**
	 * Returns prepared data for save
	 */
   prepareReply() {
		const controls = this.feedbackForm.controls;
		const _reply = {
	
	   "feedbackId" : this.feedbackId,
		"message" : controls.message.value
    }
   
	
		return _reply;
	}

	


  
  SendReply(reply) {
		this.blockUI.start('Sending message... Please wait')
		
		this.centerService.sendReply(reply).subscribe(data=>{
        this.blockUI.stop()
			const message = `Reply sent successfully`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
      this.getFeedbacks()
		})
		
	}

  getFeedbacks(){
    this.feedbackService.getFeedBacks().subscribe(data=>{
      this.onCLose(data);

    })
  }

	

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getCitiesForState(stateId){
		
		this.authService.getCitiesByStateId(stateId).subscribe(data=>{
			this.cities = data
		})

	}

	onCLose(data?){
		this.reset()
		this.dialogRef.close()
	}

	getAllStates(){
     this.authService.getAllStates().subscribe(data=>{
		 this.states = data;
	 })
  }
  getALLOfficers(){
    this.authService.getAllAllOfficers().subscribe(data=>{
      this.officers = data;
    })
  }
  getAllCenters(){
		this.vacancyService.getAVailableVacancies(1).subscribe(data=>{
      this.centers = data

    }
		
			)

  }
  
  getUSerType(){
    return this.vacancyService.getUserType()
  }

}
