import { BaseModel } from '../_base/crud';


export class Center  extends BaseModel{
    id: number;
    centerName: string;
    centerId:number;
    email:string;
   
    jwt:string;
  
   
    slogan:string;
    logo:string;
    tel:string;
    address:string;
    stateId:number;
    cityId:number;
    state:string;
    city:string;
   category:string;
    dateCreated:Date;
    createdBy:number;
    lastModified:Date;
     modifiedBy:number;
     status:string;

     clear(): void {
        this.id = undefined;
        this.centerName = '';
        this.slogan = '';
        this.email = '';
        this.tel = '';
        this.category = '';
        this.centerId =undefined;
       this.stateId=undefined;
       this.cityId=undefined;
       this.status=''
        this.jwt = localStorage.getItem('token')
       
       
    }
    


}