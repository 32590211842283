import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Layout
import { LayoutUtilsService, MessageType } from '../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../core/auth';
import { AuthService } from '../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../core/center/center.model';
import { CenterService } from '../../../core/center/center.service';
import { Vacancy } from '../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'kt-reply-feedback-thread',
  templateUrl: './reply-feedback-thread.component.html',
  styleUrls: ['./reply-feedback-thread.component.scss']
})
export class ReplyFeedbackThreadComponent implements OnInit {
id:number;
feedbackMessage:string;
feedbackId:number;
  constructor( 
    private dialogRef: MatDialogRef<ReplyFeedbackThreadComponent>,
    private feedbackService:VacanciesService,
    @Inject(MAT_DIALOG_DATA) data
    ){
      if(data){
        this.feedbackId = data.id
      }

   }

  ngOnInit() {
  }
  onCLose(){
	
		this.dialogRef.close()
	}


}
