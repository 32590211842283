export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Dashboards',
					root: true,
					alignment: 'left',
					page: '/main/dashboard',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'Training Cernter Mgt',
					root: true,
					alignment: 'left',
					toggle: 'click',
					type:'admin',
					submenu: [
						{
							title: 'Training Types',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
							submenu: [
								
								{
									title: 'Manage Training Types',
									bullet: 'dot',
									page: '/main/center-management'
									
								}
								
								
								
							]
						},
						{
							title: 'Training Centers',
							bullet: 'dot',
							icon: 'flaticon-web',
							submenu: [
								
								{
									title: 'Manage Centers',
									page: '/center-management'
								},
								{
									title: 'Payment Clearance',
									page: '/ngbootstrap/alert'
								},
								{
									title: 'Payment Management',
									page: '/ngbootstrap/alert'
								}
								
								
							]
						},
					]
				},
				{
					title: 'House  Officer Mgt',
					root: true,
					alignment: 'left',
					type:'admin',
					toggle: 'click',
					submenu: [
						{
							title: 'Add New Officer',
							bullet: 'dot',
							icon: 'flaticon-business',
							
						},
						{
							title: 'Manage Officer',
							bullet: 'dot',
							icon: 'flaticon-user',
							
						},
						{
							title: 'Post Officer',
							bullet: 'dot',
							icon: 'flaticon-user',
							
						},
					]
				},
				{
					title: 'User Mgt',
					root: true,
					alignment: 'left',
					type:'admin',
					toggle: 'click',
					submenu: [
						
						{
							title: 'User Management',
							bullet: 'dot',
							icon: 'flaticon-user',
							submenu: [
								{
									title: 'Users',
									page: '/user-management/users'
								},
								{
									title: 'Roles',
									page: '/user-management/roles'
								}
							]
						},
					]
				},
				{
					title: 'Report',
					root: true,
					alignment: 'left',
					type:'admin',
					toggle: 'click',
					submenu: [
						{
							title: 'Error Pages',
							bullet: 'dot',
							icon: 'flaticon2-list-2',
							submenu: [
								{
									title: 'Error 1',
									page: '/error/error-v1'
								},
								{
									title: 'Error 2',
									page: '/error/error-v2'
								},
								{
									title: 'Error 3',
									page: '/error/error-v3'
								},
								{
									title: 'Error 4',
									page: '/error/error-v4'
								},
								{
									title: 'Error 5',
									page: '/error/error-v5'
								},
								{
									title: 'Error 6',
									page: '/error/error-v6'
								},
							]
						},
						{
							title: 'Wizard',
							bullet: 'dot',
							icon: 'flaticon2-mail-1',
							submenu: [
								{
									title: 'Wizard 1',
									page: '/wizard/wizard-1'
								},
								{
									title: 'Wizard 2',
									page: '/wizard/wizard-2'
								},
								{
									title: 'Wizard 3',
									page: '/wizard/wizard-3'
								},
								{
									title: 'Wizard 4',
									page: '/wizard/wizard-4'
								},
							]
						},
					]
				},
			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					type:'admin',
					icon: 'flaticon2-architecture-and-city',
					page: '/main/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				
				{section: 'Training Center'},
				{
					title: 'Training Center Management',
					root: true,
					bullet: 'dot',
					type:'admin',
					icon: 'flaticon2-browser-2',
					permission: 'Manage_Center',
					submenu: [
						
						{
							title: 'Manage Training Center',
							bullet: 'dot',
							'page': '/main/center-management',
							permission: 'Manage_Center',
							
						},
						{
							title: 'Approved Vacancies',
							bullet: 'dot',
							'page': '/main/vacancy-management',
							permission: 'Manage_Vacancies',
							
						},
						{
							title: 'Create Vacancy',
							bullet: 'dot',
							'page': '/main/create-vacancy',
							permission: 'Manage_Vacancies',
							
						},
						{
							title: 'Approve Vacancies',
							bullet: 'dot',
							'page': '/main/approve-vacancy',
							permission: 'Approve_Vacancies',
							
						},
						{
							title: 'Training Center Staff',
							bullet: 'dot',
							'page': '/main/training-center-staff'
							
						}

					
						
						
					]
				},
				{section: 'Account'},
				
				{
					title: 'Monthly Clearance',
					bullet: 'dot',
					'page': '/main/mdcn-clearance',
					permission: 'Manage_Clearance',
					
				},
				{
					title: 'Doctors Account Details',
					bullet: 'dot',
					icon: 'flaticon-user',
					page:'/main/officer-account-details',
					permission: 'Manage_Account',
					
				},
				// {
				// 	title: 'Training Types',
				// 	root: true,
				// 	bullet: 'dot',
				// 	type:'admin',
				// 	icon: 'flaticon2-digital-marketing',
				// 	submenu: [
				// 		{
				// 			title: 'Add New Training Type',
				// 			page: '/ngbootstrap/accordion'
				// 		},
				// 		{
				// 			title: 'Manage Training Types',
				// 			page: '/ngbootstrap/alert'
				// 		}
						
				// 	]
				// },
				{section: 'House Officer Management'},
				// {
				// 	title: 'Officer Management',
				// 	bullet: 'dot',
				// 	icon: 'flaticon2-list-2',
				// 	type:'admin',
				// 	root: true,
				// 	//permission: 'accessToECommerceModule',
				// 	submenu: [
						
				// 		{
				// 			title: 'Manage Officer',
				// 			bullet: 'dot',
				// 			icon: 'flaticon-user',
				// 			page: '/officers'
							
				// 		},
						
				// 	]
				// },
				{
					title: 'Posting Management',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					type:'admin',
					root: true,
					permission: 'Manage_Posting',
					submenu: [
						
						{
							title: 'Manage Posting',
							bullet: 'dot',
							icon: 'flaticon-user',
							page:'/main/postings'
							
						},
						{
							title: 'Posting History',
							bullet: 'dot',
							icon: 'flaticon-time',
							page:'/main/posting-history'
							
						},
						{
							title: 'Posting Reset History',
							bullet: 'dot',
							icon: 'flaticon-time',
							page:'/main/posting-reset-history'
							
						}
						
						
						
					]
				},
				{
					title: 'House Officers',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					type:'admin',
					root: true,
					permission: 'Manage_Officer',
					submenu: [
						
						{
							title: 'Manage Officer',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers'
							
						},
						{
							title: 'Officers Awaiting Posting',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-awaiting-posting'
							
						},
						{
							title: 'Officers Awaiting Acceptance',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-awaiting-acceptance'
							
						},
						
						{
							title: 'Officers Under Training',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-under-training'
							
						},
						{
							title: 'Officers Completed',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-graduated'
							
						},
						{
							title: 'Resigned and Owing',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-resigned-owing'
							
						},
						{
							title: 'Resigned and Paidup',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-resigned-paidup'
							
						},
						{
							title: 'Resigned Not Owing',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-resigned-not-owing'
							
						},
						
						
						
					]
				},
				{section: 'Feedback Management'},
				{
					title: 'Manage Feedbacks',
					bullet: 'dot',
					page: '/main/feedback-management',
					icon: 'flaticon2-multimedia',
					type:'admin',
					permission:'Manage_Feedbacks',
					
					root: true,
					
				},
				
				{section: 'Settings'},
				
				{
					title: 'User Management',
					root: true,
					type:'admin',
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					permission:'Manage_Users',
					submenu: [
						{
							title: 'Users',
							page: '/main/user-management/users'
						},
						{
							title: 'Roles',
							page: '/main/user-management/roles',
							permission:'Manage_Roles',
						}
					]
				},
				{
					title: 'Quota Configuration ',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					type:'admin',
					root: true,
					permission:'Manage_Quota',
					submenu: [
						{
							title: 'Quota Configuration',
							page: '/main/posting-configuration'
						},
						
					]
				},
				{
					title: 'Portal Closing',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					type:'admin',
					root: true,
					permission:'Manage_Portal_Closing',
					submenu: [
						{
							title: 'Close Portal',
							page: '/main/portal-closing'
						},
						
					]
				},
				{
					title: 'Batch Management',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					type:'admin',
					root: true,
					permission:'Manage_Batch',
					submenu: [
						{
							title: 'Batch Configuration',
							page: '/main/batch-configuration'
						},
						{
							title: 'Batch Management',
							page: '/main/batch-management'
						}
					]
				},
				{
					title: 'Feedback Receivers ',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					type:'admin',
					page:'/main/feedback-receivers',
					permission:'Manage_Feedback_Recievers',
					root: true
						
					
				},
				

				{
					section:'Audit'
				},
				{
					title: 'Audit Trail',
					root: true,
					type:'admin',
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					page:'/main/audit-trail',
					permission:'Manage_Audit_Trail',
					
				},

				//{section: 'Custom'},
				// {
				// 	title: 'Error Pages',
				// 	root: true,
				// 	type:'admin',
				// 	bullet: 'dot',
				// 	icon: 'flaticon2-list-2',
				// 	submenu: [
				// 		{
				// 			title: 'Error 1',
				// 			page: '/error/error-v1'
				// 		},
				// 		{
				// 			title: 'Error 2',
				// 			page: '/error/error-v2'
				// 		},
				// 		{
				// 			title: 'Error 3',
				// 			page: '/error/error-v3'
				// 		},
				// 		{
				// 			title: 'Error 4',
				// 			page: '/error/error-v4'
				// 		},
				// 		{
				// 			title: 'Error 5',
				// 			page: '/error/error-v5'
				// 		},
				// 		{
				// 			title: 'Error 6',
				// 			page: '/error/error-v6'
				// 		},
				// 	]
				// },
				// {
				// 	title: 'Wizard',
				// 	root: true,
				// 	bullet: 'dot',
				// 	icon: 'flaticon2-mail-1',
				// 	submenu: [
				// 		{
				// 			title: 'Wizard 1',
				// 			page: '/wizard/wizard-1'
				// 		},
				// 		{
				// 			title: 'Wizard 2',
				// 			page: '/wizard/wizard-2'
				// 		},
				// 		{
				// 			title: 'Wizard 3',
				// 			page: '/wizard/wizard-3'
				// 		},
				// 		{
				// 			title: 'Wizard 4',
				// 			page: '/wizard/wizard-4'
				// 		},
				// 	]
				// },
			],
			userItem:[
				{
					title: 'Dashboard',
					root: true,
					type:'admin',
					icon: 'flaticon2-architecture-and-city',
					page: '/main/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				
				{section: 'Posting'},
				{
					title: 'View Posting',
					root: true,
					bullet: 'dot',
					
					icon: 'flaticon2-browser-2',
					submenu: [
						{
							title: 'Print Posting Letter',
							bullet: 'dot',
							page: '/main/my-posting'
							
						}
						
						
						
					]
				},
				{
					title: 'View Payment Details',
					root: true,
					bullet: 'dot',
					
					icon: 'flaticon2-digital-marketing',
					submenu: [
						{
							title: 'View Account Details',
							page: '/main/my-account-details'
						}
						// {
						// 	title: 'View Payment History',
						// 	page: '/my-payments'
						// }

					]
				},
				{section: 'Personal Profile'},
				{
					title: 'Manage Profile',
					bullet: 'dot',
					page: '/main/my-profile',
					icon: 'flaticon2-list-2',
					
					root: true,
					
				},
				{section: 'Feedback'},
				{
					title: 'contact MDCN',
					bullet: 'dot',
					page: '/main/feedback',
					icon: 'flaticon2-multimedia-2',
					
					root: true,
					
				}
				
			
				
				
				

			],
			centerItem:[
				{
					title: 'Dashboard',
					root: true,
					type:'admin',
					icon: 'flaticon2-architecture-and-city',
					page: '/main/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				
				{section: 'Posting'},
				{
					title: 'Manage Posting',
					root: true,
					bullet: 'dot',
					
					icon: 'flaticon2-browser-2',
					submenu: [
						{
							title: 'Manage Posting',
							bullet: 'dot',
							page: '/main/postings'
							
						}
						
						
						
					]
				},
				// {section: 'Vacancy Management'},
				// {
				// 	title: 'Manage Vacancies',
				// 	root: true,
				// 	bullet: 'dot',

					
				// 	icon: 'flaticon2-browser-2',
				// 	page: '/vacancies'
					
				// },
				{section: 'House Officer Management'},
				{
					title: 'House Officer Management',
					root: true,
					bullet: 'dot',
					
					icon: 'flaticon2-browser-2',
					submenu: [
						{
							title: 'Manage House Officers',
							bullet: 'dot',
							page: '/main/postings'
							
						},
						{
							title: 'Officers Completed',
							bullet: 'dot',
							icon: 'flaticon-user',
							page: '/main/house-officers-graduated'
							
						}
						
						
						
					]
				},
				{
					title: 'Manage Payment',
					root: true,
					bullet: 'dot',
					
					icon: 'flaticon2-digital-marketing',
					submenu: [
						{
							title: 'Payment Clearance',
							page: '/main/clearance'
						}
						// {
						// 	title: 'View Payment History',
						// 	page: '/my-payments'
						// }

					]
				},
				{section: 'Personal Profile'},
				{
					title: 'Manage Profile',
					bullet: 'dot',
					page: '/main/my-profile',
					icon: 'flaticon2-list-2',
					
					root: true,
					
				}
				
				
				

			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
