import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Center } from '../../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../../core/center/center.service';
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/utils/layout-utils.service';
import { UserEditComponent } from '../../user-management/users/user-edit/user-edit.component';
import { CenterEditComponent } from '../../training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesService } from '../../../../core/vacancy/vacancies.service';
import { EditPostingComponent } from '../postings/edit-posting/edit-posting.component';
import { RepostOfficerComponent } from '../postings/repost-officer/repost-officer.component';
import { OfficerDetailsComponent } from '../postings/officer-details/officer-details.component';
// import { CenterEditComponent } from '../../../.center-edit/center-edit/center-edit.component';
import Swal from 'sweetalert2'
import { Posting } from '../../../../core/vacancy/posting.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'kt-house-officers-awaiting-acceptance',
  templateUrl: './house-officers-awaiting-acceptance.component.html',
  styleUrls: ['./house-officers-awaiting-acceptance.component.scss']
})
export class HouseOfficersAwaitingAcceptanceComponent implements OnInit {

  searchKey: string;
	profileDetails: any;
  history: any;
  officers : any;
  medicalType:any;
  dentalType:any;
  exportData:any;
	@BlockUI() blockUI: NgBlockUI;
	// Table fields

	listData: MatTableDataSource<Center>;

	displayedColumns = ['folioNumber', 'firstName', 'lastName','provitionalRegistrationNumber','practiceType', 'phone', 'status', 'view', 'actions'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	// Subscriptions
	private subscriptions: Subscription[] = [];

	constructor(
		private centerService: CenterService,
		private vacancyService: VacanciesService,
		private layoutUtilsService: LayoutUtilsService,
		private authService: AuthService,
		private dialog: MatDialog
	) { }

	ngOnInit() {

		// load allusers 
		this.getAllCenters()
		this.profileDetails = this.getProfileDetails()

	




	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */


	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}



	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {

	}
	getAllCenters() {
		//this.blockUI.start('Fetching records... Please wait')
		this.vacancyService.getHouseOfficersByStatus('Awaiting_Acceptance').subscribe(
			list => {
				let array = list.map(item => {
					return item
				})
       
        this.officers = array;
		this.exportData = array;
        this.medicalType = array.filter(x=>x.practiceType=='Medical Registration')
        this.dentalType =  array.filter(x=>x.practiceType=='Dental Registration')
				this.listData = new MatTableDataSource(array)

				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
				this.blockUI.stop()

			},error=>{
				this.listData = new MatTableDataSource()
			}
		)

	}

	exportExcel() {
		const workSheet = XLSX.utils.json_to_sheet(this.exportData, {header:['Housemanship Officers Awaiting Acceptance']});
       
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Awaiting_Acceptance');
		//XLSX.utils.book_append_sheet(workBook, workSheet2, 'Clearance_Stats');
        var fileName = "Awaiting_Acceptance"+ Date.now()+".xlsx";
		XLSX.writeFile(workBook, fileName);
	}
	applyFilter() {

		this.listData.filter = this.searchKey.trim().toLowerCase();
	}
	getProfileDetails() {
		return this.vacancyService.getCurrentUserDetails()
	}
	acceptPosting(posting: Posting) {
		   
	        posting.officer = posting.userId;
			this.blockUI.start('Processing Acceptance...')
			
			return this.vacancyService.acceptOfficer(posting).subscribe(data => {
				this.getAllCenters();
				this.blockUI.stop()

				//alert(JSON.stringify(err))
				Swal.fire(
					'Accepted ',
					'Officer successfully accepted',
					'success'
				)



			}, error => {
				this.blockUI.stop()
				let err = error.error.message
				//alert(JSON.stringify(err))
				Swal.fire(
					'Oops! Acceptance was not successful',
					err,
					'error'
				)

			})
		
	}
	ResetPosting(posting: Posting) {
		//this.blockUI.start('Fecthing Posting History')
		this.authService.getPostingHistoryByFolioNumber(posting.folioNumber).subscribe(data => {

			this.history = data;
			this.blockUI.start('Reseting ...please wait')
			posting.lastcenter = this.history[this.history.length - 1].centerId
			posting.lastposter = this.history[this.history.length - 1].postedBy
			posting.officer = this.history[0].userId;
			return this.vacancyService.resetOfficer(posting).subscribe(data => {
				this.getAllCenters();
				this.blockUI.stop()

				//alert(JSON.stringify(err))
				Swal.fire(
					'Reset was successful ',
					'Officer successfully removed from posting',
					'success'
				)



			}, error => {
				this.blockUI.stop()
				let err = error.error.message
				//alert(JSON.stringify(err))
				Swal.fire(
					'Oops! Posting Reset Unsuccessful',
					err,
					'error'
				)
				this.blockUI.stop()

			})
		}, error => {
			this.blockUI.stop()
			let err = error.error.message
			//alert(JSON.stringify(err))
			Swal.fire(
				'Oops! Could not fetch posting history',
				err,
				'error'
			)

		})
	}

	onCreate() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		this.dialog.open(EditPostingComponent, dialogConfig)
	}
	onRepost() {

		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		this.dialog.open(RepostOfficerComponent, dialogConfig)
	}
	onAccept(center, id) {
	   
		


		Swal.fire({
			title: 'Are you sure you want to accept ' + center.folioNumber + ' ?',
			text: 'This officer cannot be reposted once accepted. This process cannot be reversed',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, I want to accept ' + center.folioNumber,
			cancelButtonText: 'No, Cancel'
		}).then((result) => {
			if (result.value) {
				
				this.acceptPosting(center);



			} else if (result.dismiss === Swal.DismissReason.cancel) {

			}
		})
	}

	onReset(center, id) {



		Swal.fire({
			title: 'Are you sure you want to reset posting for ' + center.folioNumber + ' ?',
			text: 'This means removing the house officer from training center. This process cannot be reversed',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, I want reset ' + center.folioNumber,
			cancelButtonText: 'No, Cancel'
		}).then((result) => {
			if (result.value) {

				// state reason for reset
				Swal.fire({
					title: 'Kindly state the reason for reset',
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Continue',
					showLoaderOnConfirm: true,
					preConfirm: (reason) => {

						center.reason = reason;
						this.blockUI.start('Reseting... Please wait')
						this.ResetPosting(center)

					},

				})



			} else if (result.dismiss === Swal.DismissReason.cancel) {

			}
		})
	}
	onEdit(center, id) {

		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		dialogConfig.data = center;
		this.dialog.open(EditPostingComponent, dialogConfig)
		//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
	}

	onView(center, id) {

		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "80%";
		dialogConfig.data = center;
		this.dialog.open(OfficerDetailsComponent, dialogConfig)
		//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
	}

}



