// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	confirmForm:FormGroup;
	loading = false;
	qRegister = false;
	errors: any = [];
	folioNumber :any;
	regType : any;
	regDate: any;
	practiceType: string;
    @BlockUI() blockUI: NgBlockUI;
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private datePipe: DatePipe
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
		this.initConfirmForm();
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initConfirmForm() {

		
		this.confirmForm = this.fb.group({
			
			folioNumber: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			dateOfIssue: ['', Validators.compose([
			 	Validators.required
				
			 ])
			 ],
			
			
			
		});
	}

	initRegisterForm() {

		
		this.registerForm = this.fb.group({
			firstName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			lastName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			otherName: [''],
			folioNumber: [this.folioNumber, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			regType: [this.regType, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			regDate: [this.regDate, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			practiceType: [this.practiceType, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			]),
			],
			phone: ['', Validators.compose([
				Validators.required,
				
				Validators.minLength(8),
				Validators.maxLength(15)
			]),
			],
			username: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			file: ['', Validators.compose([
				Validators.required
				
			]),
			],
			fileSource: ['', Validators.compose([
				Validators.required
				
			]),
			],
			agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */

	 confirm(){
		
		const controls = this.confirmForm.controls;
	
		
		if (this.confirmForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		this.blockUI.start('Prcocessing...');
		let dateOfIssue = controls.dateOfIssue.value;
		dateOfIssue =this.datePipe.transform(dateOfIssue, 'yyyy-MM-dd');
			this.auth.getDoctorStatus(controls.folioNumber.value,dateOfIssue).pipe(
			tap(data=>{

			},error=>{

			},
			),takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.blockUI.stop();
				this.cdr.markForCheck();
			}),
		).subscribe(data=>{
			this.loading = true;
			this.folioNumber = controls.folioNumber.value
			//this.regType = controls.regType.value
			this.regDate = data.doctor.regDate
			let currentDate:any = new Date();
			 currentDate =	this.datePipe.transform(currentDate, 'yyyy-MM-dd');
           
			if(data.doctor.provisionalRegStatus==false || data.doctor.provisionalExpiryDate < currentDate)
			{
			
				Swal.fire(
					'Not Eligible For House Job',
					`Dear ${data.doctor.firstName} , your provisional registration is expired.You are not eligible for house job posting. Contact MDCN for details `,
					'error'
				  )
			}
			else if(data.doctor.provisionalExpiryDate > currentDate && data.doctor.fullRegStatus){
   

				Swal.fire(
					'Not Eligible For House Job',
					`Dear ${data.doctor.firstName} , You are no longer eligible for house job posting.Contact MDCN for details `,
					'error'
				  )
			}

			else {
				this.auth.checkOfficer(this.folioNumber).subscribe(x=>{
					this.auth.checkBackLogOfficer(this.folioNumber)
					.subscribe(x=>{
						localStorage.setItem('DocData',JSON.stringify(data.doctor))	
						this.router.navigateByUrl('/auth/officer-reg');



					},
					error=>{
						Swal.fire(
							`Officer with ${this.folioNumber} is already undergoing housemanship  `,
							`Contact MDCN for details,if you are not convinced `,
							'error'
						  )

					})
  
				},error=>{
					Swal.fire(
						`Officer with ${this.folioNumber} already exists on this portal `,
						`Make the neccessary correction and try again `,
						'error'
					  )
                
				})
		
			}
			this.practiceType = data.doctor.practiceType
			this.loading = false;
		   this.qRegister = true;
		},error=>{
			Swal.fire(
				error.error.msg,
				`Make the neccessary correction and try again `,
				'error'
			  )
			
			//this.authNoticeService.setNotice('Folio number not found,please check and try again or CONTACT MDCN to get one ', 'danger');
			this.loading = false;
		})
	// 	setTimeout(x=>{
	 
		
	// 	if(controls.folioNumber.value.length<6){
			
	// 		this.authNoticeService.setNotice('Folio number not found,please check and try again or CONTACT MDCN to get one ', 'danger');
	// 		this.loading = false;
	// 		return;
			
	// 	}else {
	// 		this.folioNumber = controls.folioNumber.value
	// 		this.regType = controls.regType.value
	// 		this.loading = false;
	// 	   this.qRegister = true;
	// 	   return;
	// 	}
	// },2000)
	 }
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		if (!controls.agree.value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		}

		const _user: User = new User();
		_user.clear();
		_user.email = controls.email.value;
		_user.username = controls.username.value;
		_user.firstName = controls.firstName.value;
		_user.otherName = controls.otherName.value;
		_user.lastName = controls.lastName.value;
		_user.lastName = controls.lastName.value;
		_user.password = controls.password.value;
	//	_user.regType = controls.regType.value;
		_user.folioNumber = controls.folioNumber.value;
		_user.regDate = controls.regDate.value;
		_user.practiceType = controls.practiceType.value;
		_user.role = 2;
		const formData = new FormData();
    formData.append('file', this.registerForm.get('fileSource').value);
		this.auth.uploadFile(formData).subscribe(data=>{
			_user.pic=data.message;
			
                  this.auth.register(_user).pipe(
			tap(user => {
				Swal.fire(
					'Created',
					'Congratulations you have successfully registered a profile',
					'success'
				  )
				  setTimeout(x=>{
					this.router.navigateByUrl('/auth/login');

				  }, 2000)
				
				if (user) {
					this.store.dispatch(new Register({authToken: user.accessToken}));
					// pass notice message to the login page
					this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');
					
				} else {                  
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				}
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();

		},error=>{
			alert(JSON.stringify(error))
		}
		)
		
	}

	get f(){
		return this.registerForm.controls;
	  }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	onFileChange(event) {
  
		if (event.target.files.length > 0) {
		  const file = event.target.files[0];
		  this.registerForm.patchValue({
			fileSource: file
		  });
		}
	  }
}
