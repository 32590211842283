import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Center } from '../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../core/center/center.service';
import { LayoutUtilsService , MessageType } from '../../../core/_base/crud/utils/layout-utils.service';

import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import { EditPostingComponent } from '../officer-management/postings/edit-posting/edit-posting.component';
import { RepostOfficerComponent } from '../officer-management/postings/repost-officer/repost-officer.component';
import { EditPostingCongifurationComponent } from '../posting-congifuration/edit-posting-congifuration/edit-posting-congifuration.component';
import { EditBatchConfigurationComponent } from '../batch-configuration/edit-batch-configuration/edit-batch-configuration.component';
import { EditBachComponent } from './edit-bach/edit-bach.component';
@Component({
  selector: 'kt-batch-management',
  templateUrl: './batch-management.component.html',
  styleUrls: ['./batch-management.component.scss']
})
export class BatchManagementComponent implements OnInit {

  searchKey:string;
	// Table fields

  listData: MatTableDataSource<Center>;

	displayedColumns = [ 'batchName', 'lastModified','actions' ];

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	
	// Subscriptions
	private subscriptions: Subscription[] = [];

  constructor(
    private centerService: CenterService,
    private vacancyService: VacanciesService,
    private layoutUtilsService: LayoutUtilsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
 
		// load allusers 
		this.getAllCenters()
		
		

	
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */
	

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

		//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	

	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
    
	}
	getAllCenters(){
  
		this.vacancyService.getbatch().subscribe(
			list=>{
				let array = list.map(item =>{
       
					return item
				})
    
       
        this.listData = new MatTableDataSource(list)
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
			}
			)

	}
	applyFilter(){
		
		this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  
  onCreate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(EditBachComponent,dialogConfig )
  }

 onEdit(center,id) {
	
	const dialogConfig = new MatDialogConfig();
	dialogConfig.disableClose = true;
	dialogConfig.autoFocus = true;
	dialogConfig.width = "60%";
	dialogConfig.data= center;
	this.dialog.open(EditBachComponent,dialogConfig)
	//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
}
 
}
