import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Vacancy } from './vacancy.model';
import { Posting } from './posting.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Clearance } from './clearance.model';
import { Bank } from './bank.model';
import { environment } from '../../../environments/environment';
import { Center } from '../center/center.model';


const helper = new JwtHelperService();
const BASE_URL =environment.BASE_URL;

const API_AVAILABLEVACANCY_URL = BASE_URL+ 'api/availablevacancies';
const API_ALLAVAILABLEVACANCY_URL = BASE_URL+ 'api/allvacancies.php';

const API_DASHBAORD_URL = BASE_URL+ 'api/dashboard';
const CHECK_POSTING = BASE_URL+ 'api/checkposting';
const CHECK_CENTER_STAFF = BASE_URL+ 'api/checkstaff';
const VACANCY_URL = BASE_URL+ 'api/vacancies.php';
const VACANCY_LEVEL_URL = BASE_URL+ 'api/vacancies_draft.php';
const VACANCY_APPROVAL_URL = BASE_URL+ 'api/vacancy_approval.php';
const MANUAL_VACANCY_URL = BASE_URL+ 'api/manualvacancy';

const API_POSTING_URL = 'api/posting';
const API_RESEND_LETTER_URL = 'api/resend-letter';
const API_FEEDBACK_URL = 'api/feedback';
const API_FEEDBACKRECEIVER_URL = 'api/feedbackreceivers';
const API_POSTING_CONFIG_URL = BASE_URL+'api/postingconfig';
const API_BATCH_CONFIG_URL = BASE_URL+'api/batchconfig';
const API_PORTAL_CONFIG_URL = BASE_URL+'api/portal';

const API_BATCH = BASE_URL+'api/batch';
const API_POSTING_HISTORY_URL = BASE_URL+'api/postinghistory';
const API_POSTING_RESET_HISTORY_URL = BASE_URL+'api/postingresethistory';
const API_BANK_URL = BASE_URL+ 'api/bank';
const API_CLERANCESTATUS_URL = 'api/clerancestatus';
const API_CLEARANCE_URL = BASE_URL+ 'api/clearance';
const API_HOUSEOFFICER_URL = BASE_URL+ 'api/houseofficer';
const API_RESIGNEDOFFICER_URL = BASE_URL+ 'api/postingStanding';

const API_AUDIT = 'api/audittrail';
const API_GRADUATION = BASE_URL+'api/graduation';

const API_PERMISSION_URL = 'api/permissions';

const API_ROLES_URL = 'api/roles';


@Injectable({
  providedIn: 'root'
})
export class VacanciesService {
  

  constructor(private http: HttpClient) { }


  getAVailableVacancies(type,ptype?): Observable<Vacancy> {
   
    let data = {}
    
    //const userToken = localStorage.getItem(environment.authTokenKey);
    const userToken = localStorage.getItem('token');

    let decoded = helper.decodeToken(localStorage.getItem('token')).data;

 //alert(JSON.stringify(decoded))
 if(decoded.userType=="center"){
 let cDetails =  JSON.parse(localStorage.getItem('CDetails'))

 data ={
  'jwt':  userToken,
  'cid':cDetails.centerId,
  'tid': type
} 

 }else if(decoded.userType=="Doctor"){
 
   if(decoded.practiceType=="Dental Registration"){
     type = 2;
    
   }
   else {
     type = 1
   }
  data ={
    'jwt':  userToken,
    'tid': type
  } 
 }
 else{
  data ={
    'jwt':  userToken,
    'tid': type,
    'pType':ptype
  } 
 }
    
    
    const httpHeaders = new HttpHeaders();
    //httpHeaders.set('Authorization',  userToken);
  //  const headers = new HttpHeaders({'Authorization':'Bearer '+userToken});
  
    return this.http.post<Vacancy>(API_AVAILABLEVACANCY_URL,data).pipe(
      map(res=>res)
    )
      
}

getDashboardDetails(): Observable<Vacancy> {
  let data = {}
  
  //const userToken = localStorage.getItem(environment.authTokenKey);
  const userToken = localStorage.getItem('token');

  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

//alert(JSON.stringify(decoded))
if(decoded.userType=="center"){
let cDetails =  JSON.parse(localStorage.getItem('CDetails'))

data ={
'jwt':  userToken,
'cid':cDetails.centerId
} 

}else {
data ={
  'jwt':  userToken
} 
}
  
  
  const httpHeaders = new HttpHeaders();
  //httpHeaders.set('Authorization',  userToken);
//  const headers = new HttpHeaders({'Authorization':'Bearer '+userToken});

  return this.http.post<Vacancy>(API_DASHBAORD_URL,data).pipe(
    map(res=>res)
  )
    
}
getUserType(){
  const userToken = localStorage.getItem('token');

  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  return decoded.userType

}


postFeedBack(feedBackMsg: any): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  feedBackMsg.jwt = userToken
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post(BASE_URL+API_FEEDBACK_URL, feedBackMsg,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

updateFeedBackStatus(feedBackMsg: any): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  feedBackMsg.jwt = userToken
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put(BASE_URL+API_FEEDBACK_URL, feedBackMsg,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}


updateAccountDetails(bank: Bank): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  bank.jwt = userToken
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put(API_BANK_URL, bank,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}



postOfficer(posting: Posting): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  posting.jwt = userToken
  
  posting.id =decoded.id
  if(decoded.userType=="Doctor"){
  posting.officer = decoded.id
  if(decoded.practiceType=="Dental Registration"){
    posting.trainingType = 2;
   
  }
  else {
    posting.trainingType = 1
  }
  }
  posting.firstName = decoded.firstname
  posting.lastName = decoded.lastname
  posting.email = decoded.email
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Posting>(BASE_URL+API_POSTING_URL, posting,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

resetOfficer(posting: Posting): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  posting.jwt = userToken
  
  posting.id =decoded.id
  if(decoded.userType!="Doctor" && decoded.userType !="center" ){
  
  posting.isReset = true;
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Posting>(BASE_URL+API_POSTING_URL, posting,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}
}


resendPostingLetter(center:Center): Observable<any> {
  const userToken = localStorage.getItem('token');
  center.jwt = userToken
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Posting>(BASE_URL+API_RESEND_LETTER_URL, center,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

acceptOfficer(posting: Posting): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  posting.jwt = userToken
  
  posting.id =decoded.id
  if(decoded.userType!="Doctor"){
  
  posting.isAccepted = true;
  posting.accpter = decoded.userType;
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Posting>(BASE_URL+API_POSTING_URL, posting,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}
}



createBankDetails(posting: Bank): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  posting.jwt = userToken
  
  
  if(decoded.userType=="Doctor"){
  posting.officer = decoded.id
  }
  posting.firstName = decoded.firstname
  posting.lastName = decoded.lastname
  posting.email = decoded.email
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Bank>(API_BANK_URL, posting,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

clearOfficer(clerance: Clearance): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  clerance.jwt = userToken
  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Clearance>(API_CLEARANCE_URL, clerance,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

PayOfficer(clerance: Clearance): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  clerance.jwt = userToken
  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put<Clearance>(API_CLEARANCE_URL, clerance,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

concludeClearance(): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.get(API_GRADUATION,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

PaySingleOfficer(clerance: Clearance): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  clerance.jwt = userToken
  delete clerance.bulk;
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put<Clearance>(API_CLEARANCE_URL, clerance,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}
rePostOfficer(posting: Posting): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  posting.jwt = userToken
  
  posting.id =decoded.id
  if(decoded.userType=="Doctor"){
  posting.officer = decoded.id
  }
  posting.firstName = decoded.firstname
  posting.lastName = decoded.lastname
  posting.email = decoded.email
  posting.isRepost = true;
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Posting>(BASE_URL+API_POSTING_URL, posting,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

getPostings(folio?): Observable<any> {
  let getUrl =  API_POSTING_URL;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

 if(decoded.userType=="center"){
 let cDetails =  JSON.parse(localStorage.getItem('CDetails'))
 getUrl = API_POSTING_URL + "?gtype=c&cid="+cDetails.centerId

 
 }

 if(decoded.userType=="admin"){
  getUrl = API_POSTING_URL + "?gtype=a&folio="+folio
 
  }
  return this.http.get(BASE_URL+getUrl)
      .pipe(res=>res
        
      );
}


getFeedBacks(): Observable<any> {
  return this.http.get(BASE_URL+API_FEEDBACK_URL)
  .pipe(res=>res
    
  );
}

getFeedBacksReceivers(): Observable<any> {
  return this.http.get(BASE_URL+API_FEEDBACKRECEIVER_URL)
  .pipe(res=>res
    
  );
}


getHouseOfficers(): Observable<any> {
  let getUrl =  API_HOUSEOFFICER_URL;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

 //alert(JSON.stringify(decoded))
 if(decoded.userType=="center"){
 let cDetails =  JSON.parse(localStorage.getItem('CDetails'))
 getUrl = API_HOUSEOFFICER_URL + "?gtype=c&cid="+cDetails.centerId

 
 }

 

 if(decoded.userType=="admin"){
  getUrl = API_HOUSEOFFICER_URL + "?gtype=a"
 
  }
  return this.http.get(getUrl)
      .pipe(res=>res
        
      );
}

resignOfficer(data): Observable<any> {
  let url =  API_RESIGNEDOFFICER_URL;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  data.jwt = userToken
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post(BASE_URL+'api/posting_resignation', data,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

updateResignationStatus(data): Observable<any> {
  let url =  API_RESIGNEDOFFICER_URL;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  data.jwt = userToken
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post(BASE_URL+'api/resign_status', data,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}




getResignedOfficersByStatus(status): Observable<any> {
  let getUrl =  API_RESIGNEDOFFICER_URL;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

 //alert(JSON.stringify(decoded))
 if(decoded.userType=="center"){
 let cDetails =  JSON.parse(localStorage.getItem('CDetails'))
 getUrl = API_RESIGNEDOFFICER_URL + "?gtype=c&cid="+cDetails.centerId+"&status="+status

 
 }

 

 if(decoded.userType=="admin"){
  getUrl = API_RESIGNEDOFFICER_URL + "?gtype=a&status="+status
 
  }
  return this.http.get(getUrl)
      .pipe(res=>res
        
      );
}





getHouseOfficersByStatus(status): Observable<any> {
  let getUrl =  API_HOUSEOFFICER_URL;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

 //alert(JSON.stringify(decoded))
 if(decoded.userType=="center"){
 let cDetails =  JSON.parse(localStorage.getItem('CDetails'))
 getUrl = API_HOUSEOFFICER_URL + "?gtype=c&cid="+cDetails.centerId+"&status="+status

 
 }

 

 if(decoded.userType=="admin"){
  getUrl = API_HOUSEOFFICER_URL + "?gtype=a&status="+status
 
  }
  return this.http.get(getUrl)
      .pipe(res=>res
        
      );
}


getPostingConfig(): Observable<any> {
 
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  return this.http.get(API_POSTING_CONFIG_URL)
      .pipe(res=>res
        
      );
}

getbatchConfig(): Observable<any> {
 
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  return this.http.get(API_BATCH_CONFIG_URL)
      .pipe(res=>res
        
      );
}

getPortalConfig(): Observable<any> {
 
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  return this.http.get(API_PORTAL_CONFIG_URL)
      .pipe(res=>res
        
      );
}

updatePortalConfig(config): Observable<any> {
 
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  return this.http.put(API_PORTAL_CONFIG_URL,config)
      .pipe(res=>res
        
      );
}
getbatch(): Observable<any> {
 
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  return this.http.get(API_BATCH)
      .pipe(res=>res
        
      );
}

getCleranceStatus(centerId,month,year,status?, $from?, $to?): Observable<any> {
  $from = $from ==undefined ? 0 : $from;
	$to = $to == undefined || 0 ? 100 : $to;
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

 
  return this.http.get(BASE_URL+API_CLERANCESTATUS_URL+"?cid="+centerId+"&m="+month+"&y="+year+"&status="+status+"&vid="+decoded.id+"&uname="+decoded.username+"&from="+$from+"&to="+$to,this.getAuthHeaderOption())
      .pipe(res=>res
        
      );
}
getAuthHeaderOption(contentType = 'application/json'): any {
  const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': contentType,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      //'Access-Control-Allow-Origin': '*'
  });
  return { headers: headers };
}

getAudits(): Observable<any> {
  
  return this.http.get(BASE_URL+API_AUDIT)
      .pipe(res=>res
        
      );
}

getPostingHistory(): Observable<any> {
  
  return this.http.get(API_POSTING_HISTORY_URL)
      .pipe(res=>res
        
      );
}

getPostingResetHistory(): Observable<any> {
  
  return this.http.get(API_POSTING_RESET_HISTORY_URL)
      .pipe(res=>res
        
      );
}

postVacancy(vacancy: Vacancy): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Vacancy>(VACANCY_LEVEL_URL, vacancy,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

approveVacancy(vacancy): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  vacancy.jwt = userToken;
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put<any>(VACANCY_APPROVAL_URL, vacancy,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

postVacancyArcived(vacancy: Vacancy): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.post<Vacancy>(VACANCY_URL, vacancy,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

putVacancy(vacancy: Vacancy): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put<Vacancy>(VACANCY_URL, vacancy,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

manualEditVacancy(vacancy: Vacancy): Observable<any> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.put<Vacancy>(MANUAL_VACANCY_URL, vacancy,{ headers: httpHeaders })
      .pipe(res=>res
        
      );
}

checkposting() {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.get(CHECK_POSTING+"?officer="+decoded.id)
      .pipe(res=>res
        
      );
}

getAccountDetails(folio?): Observable<any> {
  const officerToken = JSON.parse(localStorage.getItem('PostDetails'));
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;
  let getUrl = '';
  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');

  if(decoded.userType=="center"){
    let cDetails =  JSON.parse(localStorage.getItem('CDetails'))
    getUrl = API_BANK_URL + "?gtype=c&cid="+cDetails.centerId
   
    
    }
    else if(decoded.userType=="admin"){
     getUrl = API_BANK_URL + "?gtype=a&folio="+folio
    
     }
     else {
      getUrl = API_BANK_URL +"?oid="+decoded.officerId+"&gtype=d"
     }
  return this.http.get(getUrl)
      
}

checkCenterStaff() {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.get(CHECK_CENTER_STAFF+"?user="+decoded.id)
      .pipe(res=>res
        
      );
}
getALLVacancies():Observable<Vacancy[]> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.get<Vacancy[]>(VACANCY_URL).pipe(res=>res
        
      );
}

getDraftVacancies():Observable<Vacancy[]> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.get<Vacancy[]>(VACANCY_LEVEL_URL).pipe(res=>res
        
      );
}

getAvailableVacancy():Observable<Vacancy[]> {
  const userToken = localStorage.getItem('token');
  let decoded = helper.decodeToken(localStorage.getItem('token')).data;

  
  const httpHeaders = new HttpHeaders();
  httpHeaders.set('Content-Type', 'application/json');
  return this.http.get<Vacancy[]>(API_ALLAVAILABLEVACANCY_URL).pipe(res=>res
        
      );
}


getCurrentUserDetails(){
 return helper.decodeToken(localStorage.getItem('token')).data;
}

getCenterDetails(){
return JSON.parse(localStorage.getItem('CDetails'))
}





// protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
//   let headers = new HttpHeaders({
//     'Authorization': 'Bearer ' + this.authService.accessToken,
//     'Content-Type': 'application/json',
//     'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
//     'App-Version': ConfigurationService.appVersion
//   });

//   return { headers: headers };
// }

}
