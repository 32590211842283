import { AbstractControl } from '@angular/forms';

export class ConfirmAccountNumberValidator {
	/**
	 * Check matching account number with confirm account
	 * @param control AbstractControl
	 */
	static MatchAccount(control: AbstractControl) {
		const accountNumber = control.get('accountNumber').value;

		const confirmAccountNumber = control.get('confirmAccountNumber').value;

		if (accountNumber !== confirmAccountNumber) {
			control.get('confirmAccountNumber').setErrors({ConfirmAccountNumber: true});
		} else {
			return null;
		}
	}
}
