export class Batch {
    id: number;

    batchName: string;
    jwt:string;
    

    clear() {
        this.id = undefined;
        this.batchName = '';
        this.jwt = localStorage.getItem('token')
       
    }
}
