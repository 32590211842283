import { BaseModel } from '../../_base/crud';

export class Role extends BaseModel {
    id: number;
    roleId: number;
    roleName:string;
    createdBy:string;
    title: string;
    permissions: any[];
    isCoreRole = false;
    jwt :string;

    clear(): void {
        this.id = undefined;
        this.title = '';
        this.roleId = undefined;
        this.roleName = '';
        this.createdBy = '';
        this.jwt = localStorage.getItem('token');
        this.permissions = [];
        this.isCoreRole = false;
	}
}
