export class Vacancy {
    id: number;
    centerName:string;
    centerId:number;
    year:string;
    total:number;
    state:string;
    officer:number;
    mdcn:number;
    center:number;
    mdcn_left:number;
    center_left:number;
    officer_left:number;
    batch:number;
    trainingType:number;
    type:string;
    typeId:number;
    batchName:string;
    jwt:string;

    clear(): void {
        this.id = undefined;
        this.centerName = '';
        this.centerId = undefined;
        this.state = '';
        this.batch = undefined;
        this.batchName='';
        this.total = undefined;

        this.jwt = localStorage.getItem('token')
       
       
    }
    

}