export class PostingConfig {
    id: number;
    center: number;
    officer:number;
    mdcn:number;
    jwt:string;
   
    
    clear(): void {
        this.id = undefined;
        this.center = undefined;
        this.officer = undefined;
        this.mdcn= undefined;
        this.jwt = localStorage.getItem('token')
       
       
    }



}