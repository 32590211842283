import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../../../core/center/center.model';
import { CenterService } from '../../../../../core/center/center.service';
import { Vacancy } from '../../../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../../../core/vacancy/vacancies.service';
import { VacancyEditComponent } from '../../../vacancy-management/vacancy-edit/vacancy-edit.component';
import { Officer } from '../../../../../core/auth/_models/officer.models';
import { Posting } from '../../../../../core/vacancy/posting.model';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { environment } from '../../../../../../environments/environment';

const BASE_URL =environment.BASE_URL;
@Component({
  selector: 'kt-officer-details',
  templateUrl: './officer-details.component.html',
  styleUrls: ['./officer-details.component.scss']
})
export class OfficerDetailsComponent implements OnInit, OnDestroy {
	// Public properties
  user: User;
  posting : Posting;
  center: Center;
  vacancy: Vacancy;
  officer: Officer;
	userId$: Observable<number>;
	oldUser: User;
	cName:string;
	slogan:string;
	address:string;
	tel:string;
	email:string;
	role:number;
	userType:string;
	id:number;
  states:any;
  officers:any;
	cities :any;
	stateId:number;
	cityId:number;
  city:string;
  centers:any;
  profileData:any;
  profilePicture:any;


	selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	postingForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private userFB: FormBuilder,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
				   private store: Store<AppState>,
				   private authService: AuthService,
           private centerService: CenterService,
           private vacancyService: VacanciesService,
				   private dialogRef: MatDialogRef<OfficerDetailsComponent>,
				   private layoutConfigService: LayoutConfigService,
				 
				   @Inject(MAT_DIALOG_DATA) data
				   ) { 
					  if(data){
              //alert(JSON.stringify(data))
              this.profileData = data;
    this.profilePicture = BASE_URL+"api/"+this.profileData.pic
						  this.id = data.id;
						  this.cName = data.centerName;
						  this.tel= data.tel;
						  this.slogan = data.slogan
						  this.email =data.email
						  this.address = data.address
						  this.stateId = data.stateId
						  this.cityId = data.cityId
					
						

					  }
					   
				   }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;
						this.rolesSubject.next(this.user.roles);
						this.addressSubject.next(this.user.address);
						this.soicialNetworksSubject.next(this.user.socialNetworks);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
        this.posting = new Posting();
        this.user = new User();
        this.center = new Center();
        this.vacancy = new Vacancy();
        this.vacancy.clear();
				this.center.clear();
        this.posting.clear();
        this.user.clear();
				this.rolesSubject.next(this.user.roles);
				this.addressSubject.next(this.user.address);
				this.soicialNetworksSubject.next(this.user.socialNetworks);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);

		//alert(JSON.stringify(this.user))
  //this.getAllStates()
  this.getAllCenters()
  this.getALLOfficers();
  this.userType= this.getUSerType();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (!this.user.id) {
			this.subheaderService.setTitle('Create Posting');
			this.subheaderService.setBreadcrumbs([
				{ title: 'User Management', page: `user-management` },
				{ title: 'Users',  page: `user-management/users` },
				{ title: 'Create Training Center', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit Posting');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users',  page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// this.center.centerName = this.cName
		// this.center.slogan = this.slogan
		//  this.center.tel = this.tel
		//  this.center.email = this.email
		//  this.center.address = this.address
		//  this.center.stateId = this.stateId
		//  this.center.cityId = this.cityId
		// this.getCitiesForState(this.stateId)
		
		this.postingForm = this.userFB.group({
	
			centerName: [this.posting.centerName, Validators.required],
			batch: [this.posting.batch, Validators.required],
			year: [this.posting.year, Validators.required],
			officer: [this.posting.officer, Validators.required],
			jwt:[this.user.jwt],
		
			
		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.postingForm.markAsPristine();
  this.postingForm.markAsUntouched();
  this.postingForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.postingForm.controls;
		/** check form */
		if (this.postingForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedCenter = this.prepareCenter();
  
		
		if (editedCenter.id > 0) {
			this.updatePosting(editedCenter, withBack);
			return;
		}

		this.addPosting(editedCenter, withBack);
	}

	/**
	 * Returns prepared data for save
	 */
	prepareCenter(): Posting {
		const controls = this.postingForm.controls;
		const _posting = new Posting();
		_posting.clear();
		
		
		_posting.id = this.posting.id;
		_posting.centerName = controls.centerName.value;
    _posting.batch= controls.batch.value;
    _posting.officer  = controls.officer.value;
	
		return _posting;
	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addPosting(_posting: Posting, withBack: boolean = false) {
		
		this.vacancyService.postOfficer(_posting).subscribe(data=>{

			const message = `New Posting successfully added.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose();

		},error=>{
      let err = error.error.message
      //alert(JSON.stringify(err))
      Swal.fire(
        'Oops! Posting Unsuccessful',
        err,
        'error'
        )
    })
	
	}

	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updatePosting(_posting: Posting, withBack: boolean = false) {
		
		// this.centerService.updatePosting(_posting).subscribe(data=>{

		// 	const message = `center successfully updated.`;
		// 	this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
		// 	this.onCLose();

		// })
		
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Create New Posting';
		if (!this.user || !this.user.id) {
			return result;
		}

		result = `Edit Vacancy  - ${this.vacancy.centerName}`;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getCitiesForState(stateId){
		
		this.authService.getCitiesByStateId(stateId).subscribe(data=>{
			this.cities = data
		})

	}

	onCLose(){
		this.reset()
		this.dialogRef.close()
	}

	getAllStates(){
     this.authService.getAllStates().subscribe(data=>{
		 this.states = data;
	 })
  }
  getALLOfficers(){
    this.authService.getAllAllOfficers().subscribe(data=>{
      this.officers = data;
    })
  }
  getAllCenters(){
		this.vacancyService.getAVailableVacancies(1).subscribe(data=>{
      this.centers = data

    }
		
			)

  }
  
  getUSerType(){
    return this.vacancyService.getUserType()
  }
}
