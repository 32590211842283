import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Center } from '../../../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../../../core/center/center.service';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud/utils/layout-utils.service';
import { UserEditComponent } from '../../../user-management/users/user-edit/user-edit.component';
import { CenterEditComponent } from '../../../training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesService } from '../../../../../core/vacancy/vacancies.service';
import { EditPostingComponent } from '../edit-posting/edit-posting.component';
import { RepostOfficerComponent } from '../repost-officer/repost-officer.component';
import { OfficerDetailsComponent } from '../officer-details/officer-details.component';
// import { CenterEditComponent } from '../../../.center-edit/center-edit/center-edit.component';
import Swal from 'sweetalert2'
import { Posting } from '../../../../../core/vacancy/posting.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
@Component({
  selector: 'kt-posting-reset-history',
  templateUrl: './posting-reset-history.component.html',
  styleUrls: ['./posting-reset-history.component.scss']
})
export class PostingResetHistoryComponent implements OnInit {

  searchKey:string;
	// Table fields

  listData: MatTableDataSource<Center>;

	displayedColumns = [ 'folioNumber','reason','resetBy', 'resetDate'];

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	
	// Subscriptions
	private subscriptions: Subscription[] = [];

  constructor(
    private centerService: CenterService,
    private vacancyService: VacanciesService,
    private layoutUtilsService: LayoutUtilsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
 
		// load allusers 
		this.getAllCenters()
		//this.getAudit();
		

	
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */
	

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

		//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	

	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
    
	}
	getAllCenters(){
		this.vacancyService.getPostingResetHistory().subscribe(
			list=>{
				let array = list.map(item =>{
					return item
				})
        
       
        this.listData = new MatTableDataSource(array)
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
			}
			)

  }
  getAudit(){
    this.vacancyService.getPostingHistory().subscribe(
      list=>{
				let array = list.map(item =>{
					return item
				})
        
       
        this.listData = new MatTableDataSource(array)
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
			}
			)
  }
	applyFilter(){
		
		this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  
  onCreate(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    //this.dialog.open(EditPostingComponent,dialogConfig )
  }
  onRepost(){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    //this.dialog.open(RepostOfficerComponent,dialogConfig )
  }
 onEdit(center,id) {
	
	const dialogConfig = new MatDialogConfig();
	dialogConfig.disableClose = true;
	dialogConfig.autoFocus = true;
	dialogConfig.width = "60%";
	dialogConfig.data= center;
//	this.dialog.open(EditPostingComponent,dialogConfig)
	//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
}
 
}
