import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../core/auth';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../../core/center/center.model';
import { CenterService } from '../../../../core/center/center.service';
import { Vacancy } from '../../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../../core/vacancy/vacancies.service';
import { VacancyEditComponent } from '../../vacancy-management/vacancy-edit/vacancy-edit.component';
import { Officer } from '../../../../core/auth/_models/officer.models';
import { Posting } from '../../../../core/vacancy/posting.model';
import { error } from '@angular/compiler/src/util';
import Swal from 'sweetalert2'
import { PostingConfig } from '../../../../core/vacancy/postingconfig.model';
import { BatchConfig } from '../../../../core/vacancy/batchconfig.model';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'kt-edit-batch-configuration',
  templateUrl: './edit-batch-configuration.component.html',
  styleUrls: ['./edit-batch-configuration.component.scss']
})
export class EditBatchConfigurationComponent implements OnInit {
  user: User;
  posting : Posting;
  center: Center;
  vacancy: Vacancy;
  officer: Officer;
	userId$: Observable<number>;
	oldUser: User;
	cName:string;
	slogan:string;
	address:string;
	tel:string;
	email:string;
	role:number;
	userType:string;
	id:number;
  states:any;
  officers:any;
	cities :any;
	stateId:number;
	cityId:number;
  city:string;
  centers:any;
  mdcnQuota:any;
  officerQuota:any;
  centerQuota:any;
  currentBatch :any;
  @BlockUI() blockUI: NgBlockUI;


	selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	postingForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private userFB: FormBuilder,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
				   private store: Store<AppState>,
				   private authService: AuthService,
           private centerService: CenterService,
           private vacancyService: VacanciesService,
				   private dialogRef: MatDialogRef<EditBatchConfigurationComponent>,
				   private layoutConfigService: LayoutConfigService,
				 
				   @Inject(MAT_DIALOG_DATA) data
				   ) { 
					  if(data){
                          this.id = data.id;
          
						  this.currentBatch = data.batchId
						
					
						

					  }
					   
				   }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;
						this.rolesSubject.next(this.user.roles);
						this.addressSubject.next(this.user.address);
						this.soicialNetworksSubject.next(this.user.socialNetworks);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
        this.posting = new Posting();
        this.user = new User();
        this.center = new Center();
        this.vacancy = new Vacancy();
        this.vacancy.clear();
				this.center.clear();
        this.posting.clear();
        this.user.clear();
				this.rolesSubject.next(this.user.roles);
				this.addressSubject.next(this.user.address);
				this.soicialNetworksSubject.next(this.user.socialNetworks);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);

		//alert(JSON.stringify(this.user))
  //this.getAllStates()
  this.getAllCenters()
  this.getALLOfficers();
  this.userType= this.getUSerType();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (!this.user.id) {
			this.subheaderService.setTitle('Create Posting');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Posting Management', page: `user-management` },
				{ title: 'Users',  page: `user-management/users` },
				{ title: 'Create Training Center', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit Posting');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users',  page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// this.center.centerName = this.cName
		// this.center.slogan = this.slogan
		//  this.center.tel = this.tel
		//  this.center.email = this.email
		//  this.center.address = this.address
		//  this.center.stateId = this.stateId
		//  this.center.cityId = this.cityId
		// this.getCitiesForState(this.stateId)
		
		this.postingForm = this.userFB.group({
	
      
			batch:[this.currentBatch,Validators.required],
     
			jwt:[this.user.jwt],
		
			
		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.postingForm.markAsPristine();
  this.postingForm.markAsUntouched();
  this.postingForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.postingForm.controls;
		/** check form */
		if (this.postingForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedCenter = this.prepareCenter();
  
		
		if (editedCenter.id > 0) {
			this.updateBatch(editedCenter, withBack);
			return;
		}

		this.addBatch(editedCenter, withBack);
	}

	/**
	 * Returns prepared data for save
	 */
	prepareCenter(): BatchConfig {
		const controls = this.postingForm.controls;
		const _posting = new BatchConfig();
		_posting.clear();
		
		
		_posting.id = this.id;
		_posting.batchId = controls.batch.value;
    
	
		return _posting;
	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addBatch(_posting: BatchConfig, withBack: boolean = false) {
		
		// this.vacancyService.postOfficer(_posting).subscribe(data=>{

		// 	const message = `New Posting successfully added.`;
		// 	this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
		// 	this.onCLose();

		// },error=>{
    //   let err = error.error
    //   alert(JSON.stringify(err))
    //   Swal.fire(
    //     'Oops! Posting Unsuccessful',
    //     err,
    //     'error'
    //     )
    // })
	
	}

	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updateBatch(_posting: BatchConfig, withBack: boolean = false) {
		this.blockUI.start('Updating batch configuration... Please wait')
		
		this.centerService.UpdateBatchConfig(_posting).subscribe(data=>{
        this.blockUI.stop()
			const message = `Batch Configuration Successfully Updated.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose();

		})
		
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Edit Batch Configuration';
		if (!this.user || !this.user.id) {
			return result;
		}

		result = `Edit Posting Configuration `;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getCitiesForState(stateId){
		
		this.authService.getCitiesByStateId(stateId).subscribe(data=>{
			this.cities = data
		})

	}

	onCLose(){
		this.reset()
		this.dialogRef.close()
	}

	getAllStates(){
     this.authService.getAllStates().subscribe(data=>{
		 this.states = data;
	 })
  }
  getALLOfficers(){
    this.authService.getAllAllOfficers().subscribe(data=>{
      this.officers = data;
    })
  }
  getAllCenters(){
		this.vacancyService.getAVailableVacancies(1).subscribe(data=>{
      this.centers = data

    }
		
			)

  }
  
  getUSerType(){
    return this.vacancyService.getUserType()
  }
}
