// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Observable, of, Subscription} from 'rxjs';
// Lodash
import { each, find, some } from 'lodash';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// Services and Models
import {
	Role,
	Permission,
	selectRoleById,
	RoleUpdated,
	selectAllPermissions,
	selectAllRoles,
	selectLastCreatedRoleId,
	RoleOnServerCreated
} from '../../../../../core/auth';
import { delay } from 'rxjs/operators';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

@Component({
	selector: 'kt-role-edit-dialog',
	templateUrl: './role-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class RoleEditDialogComponent implements OnInit, OnDestroy {
	// Public properties
	role: Role;
	role$: Observable<Role>;
	hasFormErrors = false;
	viewLoading = false;
	loadingAfterSubmit = false;
	allPermissions$: Observable<any[]>;
	allpermissions: any[] = [];
	rolePermissions: any[] = [];
	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<RoleEditDialogComponent>
	 * @param data: any
	 * @param store: Store<AppState>
	 */
	constructor(public dialogRef: MatDialogRef<RoleEditDialogComponent>,
		           @Inject(MAT_DIALOG_DATA) public data: any,
				   private store: Store<AppState>,
				   public authService: AuthService,
				   private layoutUtilsService: LayoutUtilsService
				   ) { 
					  
				   }

	
	/**
	 * On init
	 */
	ngOnInit() {
	
		if (this.data.roleId) {
			
			 this.authService.getRoleById(this.data.roleId)
			.subscribe(res=>{
				res = res[0];
				this.role = new Role();
			this.role.id = res.roleId;
			this.role.title = res.title;
			this.role.roleName = res.roleName;
			this.role.permissions = res.permissions;
			this.role.isCoreRole = res.isCoreRole;

			this.allPermissions$ = this.store.pipe(select(selectAllPermissions));

			this.loadPermissions();

			});
			//this.role$ = this.store.pipe(select(selectRoleById(this.data.roleId)));
		} else {
			const newRole = new Role();
			newRole.clear();
			this.role$ = of(newRole);
			this.role$.subscribe(res => {
				if (!res) {
					return;
				}
	
				this.role = new Role();
				this.role.id = res.id;
				this.role.title = res.title;
				this.role.roleName = res.roleName;
				this.role.permissions = res.permissions;
				this.role.isCoreRole = res.isCoreRole;
	
				this.allPermissions$ = this.store.pipe(select(selectAllPermissions));
	
				
			});
			//this.getAllPermissions();
			this.loadPermissions()
		}

		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	/**
	 * Load permissions
	 */
	loadPermissions() {
		// this.allPermissions$.subscribe(_allPermissions => {
		// 	if (!_allPermissions) {
		// 		return;
		// 	}
		this.authService.getAllPermissions().subscribe(_allPermissions=>
			{
					if (!_allPermissions) {
				return;
			}
				this.allpermissions = _allPermissions;
				const mainPermissions = _allPermissions.filter(el => !el.parentId);
				console.log(mainPermissions)
				console.log(this.role.permissions)
				mainPermissions.forEach((element) => {
					const hasUserPermission = this.role.permissions.some(t => t.permissionId === element.permissionId);
					const rootPermission = new Permission();
					rootPermission.clear();
				rootPermission.isSelected = hasUserPermission;
				rootPermission._children = [];
				rootPermission.permissionId = element.permissionId;
				rootPermission.level = element.level;
				rootPermission.parentId = element.parentId;
				rootPermission.permissionDescription = element.permissionDescription;
				const children = _allPermissions.filter(el => el.parentId && el.parentId === element.permissionId);
				children.forEach(child => {
					const hasUserChildPermission = this.role.permissions.some(t => t === child.permissionId);
					const childPermission = new Permission();
					childPermission.clear();
					childPermission.isSelected = hasUserChildPermission;
					childPermission._children = [];
					childPermission.permissionId = child.permissionId;
					childPermission.level = child.level;
					childPermission.parentId = child.parentId;
					childPermission.permissionDescription = child.permissionDescription;
					rootPermission._children.push(childPermission);
				});

				this.rolePermissions.push(rootPermission);
			});
			//alert('role permission '+JSON.stringify(this.rolePermissions ))

		});
	}

	/** ACTIONS */
	/**
	 * Returns permissions ids
	 */
	preparePermissionIds(): number[] {
		const result = [];
		each(this.rolePermissions, (_root: Permission) => {
			if (_root.isSelected) {
				result.push(_root.permissionId);
				each(_root._children, (_child: Permission) => {
					if (_child.isSelected) {
						result.push(_child.permissionId);
					}
				});
			}
		});
		return result;
	}

	/**
	 * Returns role for save
	 */
	prepareRole(): Role {
		const _role = new Role();
		_role.id = this.role.id;
		_role.roleId = this.role.id;
		_role.permissions = this.preparePermissionIds();
		_role.roleName = this.role.roleName;
		_role.isCoreRole = false
		return _role;
	}

	/**
	 * Save data
	 */
	onSubmit() {
		this.hasFormErrors = false;
		this.loadingAfterSubmit = false;
		/** check form */
		if (!this.isTitleValid()) 
		{
			this.hasFormErrors = true;
			return;
		}

		const editedRole = this.prepareRole();
		if (editedRole.id > 0) {
			this.updateRole(editedRole);
		} else {
			this.createRole(editedRole);
		}
	}

	/**
	 * Update role
	 *
	 * @param _role: Role
	 */
	updateRole(_role: Role) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
	
		this.authService.updateRole(_role).subscribe(data=>{
			const message = `Role successfully added.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.dialogRef.close(data)


		},error=>{
			alert(JSON.stringify(error))
		})
		this.componentSubscriptions = this.store.pipe(
			select(selectLastCreatedRoleId)
		).subscribe(res => {
			if (!res) {
				return;
			}

			this.viewLoading = false;
			this.dialogRef.close({
				_role,
				isEdit: false
			});
		});
	}


	/**
	 * Create role
	 *
	 * @param _role: Role
	 */
	createRole(_role: Role) {
		this.loadingAfterSubmit = true;
		this.viewLoading = true;
	
		this.authService.createRole(_role).subscribe(data=>{
			const message = `New Role successfully added.`;
			this.layoutUtilsService.showActionNotification(message,MessageType.Create, 5000, true, true);
			this.dialogRef.close()


		},error=>{
			alert(JSON.stringify(error))
		})
		this.componentSubscriptions = this.store.pipe(
			delay(1000), // Remove this line
			select(selectLastCreatedRoleId)
		).subscribe(res => {
			if (!res) {
				return;
			}

			this.viewLoading = false;
			this.dialogRef.close({
				_role,
				isEdit: false
			});
		});
	}

	/**
	 * Close alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	/**
	 * Check is selected changes
	 *
	 * @param $event: Event
	 * @param permission: Permission
	 */
	isSelectedChanged($event, permission: Permission) {
	
		if (permission._children.length === 0 && permission.isSelected) {
			const _root = find(this.rolePermissions, (item: Permission) => item.id === permission.parentId);
			if (_root && !_root.isSelected) {
				_root.isSelected = true;
			}
			return;
		}

		if (permission._children.length === 0 && !permission.isSelected) {
			const _root = find(this.rolePermissions, (item: Permission) => item.id === permission.parentId);
			if (_root && _root.isSelected) {
				if (!some(_root._children, (item: Permission) => item.isSelected === true)) {
					_root.isSelected = false;
				}
			}
			return;
		}

		if (permission._children.length > 0 && permission.isSelected) {
			each(permission._children, (item: Permission) => item.isSelected = true);
			return;
		}

		if (permission._children.length > 0 && !permission.isSelected) {
			each(permission._children, (item: Permission) => {
				item.isSelected = false;
			});
			return;
		}
	}

	/** UI */
	/**
	 * Returns component title
	 */
	getTitle(): string {
		if (this.role && this.role.id) {
			// tslint:disable-next-line:no-string-throw
			return `Edit role '${this.role.title}'`;
		}

		// tslint:disable-next-line:no-string-throw
		return 'New role';
	}

	/**
	 * Returns is title valid
	 */
	isTitleValid(): boolean {
		return (this.role && this.role.roleName && this.role.roleName.length > 0);
	}
	getAllPermissions(){
		this.authService.getAllPermissions().subscribe(data=>
			{
				this.allpermissions = data;
				this.loadPermissions();
			}

			
			)

	}

	containsPermission(obj, list) {
		var x;
		for (x in list) {
			if (list.hasOwnProperty(x) && list[x] === obj) {
				return true;
			}
		}
	
		return false;
	}
}
