// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
const helper = new JwtHelperService();

@Injectable()
export class MenuAsideService {
	public user$ : any;
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(private menuConfigService: MenuConfigService) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
		this.user$ = helper.decodeToken(localStorage.getItem('token')).data;
		let filteredMenu = ''
		if(this.user$.userType=="Doctor"){
			filteredMenu = 'aside.userItem'
		}
		else if (this.user$.userType=="center"){
			filteredMenu = 'aside.centerItem'

		}
		else {
			filteredMenu = 'aside.items'
		}
		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), filteredMenu);
		//alert(JSON.stringify(menuItems))
		this.menuList$.next(menuItems);
	}
}
