import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Center } from '../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../core/center/center.service';
import { LayoutUtilsService , MessageType } from '../../../core/_base/crud/utils/layout-utils.service';
import { UserEditComponent } from '../user-management/users/user-edit/user-edit.component';
import { CenterEditComponent } from './center-edit/center-edit/center-edit.component';

@Component({
  selector: 'kt-training-center-management',
  templateUrl: './training-center-management.component.html',
  styleUrls: ['./training-center-management.component.scss']
})
export class TrainingCenterManagementComponent implements OnInit {
	searchKey:string;
	// Table fields

	listData: MatTableDataSource<Center>;
	displayedColumns = [ 'id', 'centerName', 'email', 'address','state','city','tel','status', 'actions'];

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	
	// Subscriptions
	private subscriptions: Subscription[] = [];

  constructor(
    private centerService: CenterService,
    private layoutUtilsService: LayoutUtilsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
 
		// load allusers 
		this.getAllCenters()
		
		

	
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */
	

	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Disable Center';
		const _description = 'Are you sure you want to disable this center?';
		const _waitDesciption = 'Disabling....';
		const _deleteMessage = `Center has been disabled`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

		//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	

	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {
    
	}
	getAllCenters(){
		this.centerService.getALLCenters().subscribe(
			list=>{
				let array = list.map(item =>{
					return item
				})
        
       
        this.listData = new MatTableDataSource(array)
        
				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
			
				
			}
			)

	}
	applyFilter(){
		
		this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  
  onCreate(){
	 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
	const dialogRef = this.dialog.open(CenterEditComponent,dialogConfig )
	dialogRef.afterClosed().subscribe(
		list=>{
			let array = list.map(item =>{
				return item
			})
	
   
	this.listData = new MatTableDataSource(array)
	
			this.listData.sort = this.sort
			this.listData.paginator = this.paginator
		
			
		}
		)
  }
 onEdit(center,id) {
	
	const dialogConfig = new MatDialogConfig();
	dialogConfig.disableClose = true;
	dialogConfig.autoFocus = true;
	dialogConfig.width = "60%";
	dialogConfig.data= center;
	const dialogRef = this.dialog.open(CenterEditComponent,dialogConfig )
	dialogRef.afterClosed().subscribe(
		list=>{
			let array = list.map(item =>{
				return item
			})
	
   
	this.listData = new MatTableDataSource(array)
	
			this.listData.sort = this.sort
			this.listData.paginator = this.paginator
		
			
		}
		)
	//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
}
 
}
