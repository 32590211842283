import { Component, OnInit } from '@angular/core';
import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import { environment } from '../../../../environments/environment';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { EditPasswordComponent } from './edit-password/edit-password.component';

const BASE_URL =environment.BASE_URL;
@Component({
  selector: 'kt-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  profileData : any
  profilePicture :any;

  constructor(private vacancyService: VacanciesService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.profileData = this.getProfileDetails()
    
    this.profilePicture = BASE_URL+"api/"+this.profileData.pic
   
  }

  getProfileDetails(){
    return this.vacancyService.getCurrentUserDetails()
  }
  onPasswordEdit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    this.dialog.open(EditPasswordComponent,dialogConfig )
  }

}
