import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { States } from '../_models/states.model';
import { Officer } from '../_models/officer.models';
import * as sha512 from 'js-sha512';
import { MDCNAPI } from '../_models/mdcn-api.model';
import { Batch } from '../_models/batch.mdel';


const BASE_URL =environment.BASE_URL;
const MDCN_API = environment.MDCN_API;
const LOGIN_URL =BASE_URL+'api/login'
const API_REGISTER_URL = BASE_URL+ 'api/register';
const API_INTERNAL_USER_CREATE = BASE_URL + 'api/registerinternalusers';
const API_USERS_URL = BASE_URL+ 'api/user';

const API_PASSWORD_URL = BASE_URL+ 'api/upassword';
const API_OFFICERS_URL = BASE_URL+ 'api/houseofficer';
const API_POSTING_HISTORY_URL = BASE_URL+ 'api/postinghistory';
const API_STATES_URL = BASE_URL+ 'api/states'
const API_BATCH_URL = BASE_URL+ 'api/batches'
const API_CITIES_URL = BASE_URL+ 'api/cities'
const API_PERMISSION_URL = BASE_URL+'api/permissions';
const API_ROLES_URL =BASE_URL+ 'api/role';
const API_ROLES_URL2 =BASE_URL+ 'api/role2';

const API_UPLOAD_URL = BASE_URL +'api/upload';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {}
    // Authentication/Authorization
    login(email: string, password: string): Observable<User> {
        return this.http.post<User>(API_USERS_URL, { email, password });
    }

    getUserByToken(): Observable<User> {
        //const userToken = localStorage.getItem(environment.authTokenKey);
        const userToken = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Authorization', 'Bearer ' + userToken);
        return this.http.get<User>(API_USERS_URL, { headers: httpHeaders });
    }

    getDoctorStatus(regType,folio):Observable<any>{
       let req = {
            "regType": regType,
            "folio": folio,
            "hash": sha512.sha512(regType+folio+87452832+65443623)
            }
            const userToken = localStorage.getItem('token');
            const httpHeaders = new HttpHeaders();
            httpHeaders.set('merchantId', '65443623');
            return this.http.post(MDCN_API, req, { headers: httpHeaders });
    }

    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    InternalUserregister(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_INTERNAL_USER_CREATE, user, { headers: httpHeaders })
            
    }

    InternalUserUpdate(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put<User>(API_INTERNAL_USER_CREATE, user, { headers: httpHeaders })
            
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
    	return this.http.get(API_USERS_URL + '/forgot?=' + email)
    		.pipe(catchError(this.handleError('forgot-password', []))
	    );
    }

// Roles Sections 


    getAllUsers(username?):Observable<User[]> {
    let url =API_USERS_URL;
    if(username != undefined){
      url = url+"?userq="+username;
    }
		return this.http.get<User[]>(url).pipe(res=>res)
    }
    getAllUsersByType(userType):Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL+"?t="+userType).pipe(res=>res)
    }
   

    getAllCenterStaff():Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL).pipe(res=>res)
    }

    
    getAllAllOfficers():Observable<Officer[]> {
		return this.http.get<Officer[]>(API_OFFICERS_URL).pipe(res=>res)
    }
    getOfficerByFolioNumber(folio:string):Observable<Officer[]> {
		return this.http.get<Officer[]>(API_OFFICERS_URL+'?folioNumber='+folio).pipe(res=>res)
    }
    getPostingHistoryByFolioNumber(folio:string):Observable<Officer[]> {
		return this.http.get<Officer[]>(API_POSTING_HISTORY_URL+'?folioNumber='+folio).pipe(res=>res)
    }

    getUserById(userId: number): Observable<User> {
		return this.http.get<User>(API_USERS_URL + `/${userId}`);
	}

    getAllStates():Observable<States[]> {
		return this.http.get<States[]>(API_STATES_URL).pipe(res=>res)
    }

    getAllBatches():Observable<Batch[]> {
		return this.http.get<Batch[]>(API_BATCH_URL).pipe(res=>res)
    }
    getCitiesByStateId(stateId:number) {
        return this.http.get<States[]>(API_CITIES_URL+"?state="+stateId).pipe(res=>res)
   
      }

    // DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${API_USERS_URL}/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
    }
    
    updatePassword(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_PASSWORD_URL, _user, { headers: httpHeaders });
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
    	const httpHeaders = new HttpHeaders();
     httpHeaders.set('Content-Type', 'application/json');
		   return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders});
    }
    
    uploadFile(formData): Observable<any> {
    	
		   return this.http.post(API_UPLOAD_URL,formData, );
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders});
    }

    // Permission
    getAllPermissions(): Observable<any[]> {
		return this.http.get<any[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<any[]> {
        return this.http.get<any[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `?roleId=${roleId}`);
    }

	createRole(role: Role): Observable<Role> 
  {
        role.jwt = localStorage.getItem('token');
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<Role>(API_ROLES_URL2, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
       role.jwt = localStorage.getItem('token')
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_ROLES_URL2, role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders});
	}

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
