import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'kt-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  message:any;

feedbackForm: FormGroup;
profileData:any;
@BlockUI() blockUI: NgBlockUI;
  constructor(
    private fb: FormBuilder,
    private vacancyService: VacanciesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initFeedBackForm()
    this.profileData = this.getProfileDetails()
    
  }

  initFeedBackForm() {
		this.feedbackForm = this.fb.group({
			subject: ["",Validators.compose([
				Validators.required,	
				Validators.minLength(3),
				Validators.maxLength(150) 
			])
			],
			message: ["", Validators.compose([
				Validators.required
		
			])
			]
		});
  }
  
  submit() {
   
		const controls = this.feedbackForm.controls;
		/** check form */
		if (this.feedbackForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
    }

    this.blockUI.start('Prcocessing...');
		const feedbackData = {
			subject: controls.subject.value,
      message: controls.message.value,
      folioNumber:this.profileData.folioNumber,
      email:this.profileData.email,
      tel: this.profileData.phone,
      firstName : this.profileData.firstname,
      lastName:this.profileData.lastname,
      id:this.profileData.id,
      officer:this.profileData.officerId

    };
    
   this.vacancyService.postFeedBack(feedbackData).subscribe(x=>{
    this.blockUI.stop()
    Swal.fire(
      'Sent',
      'Message Successfully sent to MDCN ',
      'success'
      )
      this.router.navigateByUrl('/main/dashboard')
   },error=>{
    this.blockUI.stop()
    Swal.fire(
      'Feedback not sent',
      'Somethinaag happened and we coould not send your feedback, please try again later ',
      'error'
      )

   })
    
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.feedbackForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

  getProfileDetails(){
    return this.vacancyService.getCurrentUserDetails()
  }

}
