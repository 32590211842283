import { Component, OnInit } from '@angular/core';
import { VacanciesService } from '../../../core/vacancy/vacancies.service';
import { environment } from '../../../../environments/environment';
const BASE_URL =environment.BASE_URL;

@Component({
  selector: 'kt-my-posting',
  templateUrl: './my-posting.component.html',
  styleUrls: ['./my-posting.component.scss']
})
export class MyPostingComponent implements OnInit {
  postDetails:any;
  posted :any = "null";
  profilePicture:any;
  profileData:any;
  letter:any;
  registrar:any;

  constructor(private vacanCyService: VacanciesService) {

    this.checkposting()
   }

  ngOnInit() {
    this.checkposting()
  }
  checkposting(){
		this.vacanCyService.checkposting().subscribe((data)=>{
      this.postDetails = data[0];
      //alert(JSON.stringify(this.postDetails))
      this.profileData = this.getProfileDetails()
      //alert(JSON.stringify(this.profileData))

      this.profilePicture = BASE_URL+"api/"+this.profileData.pic
      this.letter= BASE_URL+"api/"+this.postDetails.letter
      this.registrar=BASE_URL+"api/registrar.jpeg"
      
      this.posted = true;
     
    },
    error=>{
      this.posted = false;
    })
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}
getProfileDetails(){
  return this.vacanCyService.getCurrentUserDetails()
}

}
