import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud';
// Services and Models
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../core/auth';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { inject } from '@angular/core/testing';
import { Center } from '../../../../core/center/center.model';
import { CenterService } from '../../../../core/center/center.service';
import { Vacancy } from '../../../../core/vacancy/vacancy.model';
import { VacanciesService } from '../../../../core/vacancy/vacancies.service';
import Swal from 'sweetalert2';
import { ConfirmPasswordValidator } from '../../auth/officer-reg/confirm-password.validator';

@Component({
  selector: 'kt-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit, OnDestroy {
	// Public properties
  user: User;
  center: Center;
  vacancy: Vacancy;
	userId$: Observable<number>;
	oldUser: User;
	cName:string;
	slogan:string;
	address:string;
	tel:string;
	email:string;
	role:number;
	userType:string;
	id:number;
	states:any;
	cities :any;
	stateId:number;
	cityId:number;
  city:string;
  centers:any;
  centerId:any;
  batch:any;
  batchId:any;
  year:any;
  total:any;


	selectedTab = 0;
	loading$: Observable<boolean>;
	rolesSubject = new BehaviorSubject<number[]>([]);
	addressSubject = new BehaviorSubject<Address>(new Address());
	soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	vacancyForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private userFB: FormBuilder,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
				   private store: Store<AppState>,
				   private authService: AuthService,
           private centerService: CenterService,
           private vacancyService: VacanciesService,
				   private dialogRef: MatDialogRef<EditPasswordComponent>,
				   private layoutConfigService: LayoutConfigService,
				 
				   @Inject(MAT_DIALOG_DATA) data
				   ) { 
					  if(data){
						
						  this.id = data.id;
						  this.cName = data.centerName;
						  this.tel= data.tel;
						  this.slogan = data.slogan
						  this.email =data.email
						  this.address = data.address
						  this.stateId = data.stateId
						  this.cityId = data.cityId
						  this.centerId = data.centerId
						  this.batchId = data.batchId
						  this.year = data.year;
						  this.total = data.total;
					
						

					  }
					   
				   }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					if (res) {
						this.user = res;
						this.rolesSubject.next(this.user.roles);
						this.addressSubject.next(this.user.address);
						this.soicialNetworksSubject.next(this.user.socialNetworks);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
				this.user = new User();
        this.center = new Center();
        this.vacancy = new Vacancy();
        this.vacancy.clear();
				this.center.clear();
				this.user.clear();
				this.rolesSubject.next(this.user.roles);
				this.addressSubject.next(this.user.address);
				this.soicialNetworksSubject.next(this.user.socialNetworks);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);

		//alert(JSON.stringify(this.user))
  //this.getAllStates()
  this.getAllCenters()
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (!this.user.id) {
			this.subheaderService.setTitle('Create Training Center');
			this.subheaderService.setBreadcrumbs([
				{ title: 'User Management', page: `user-management` },
				{ title: 'Users',  page: `user-management/users` },
				{ title: 'Create Training Center', page: `user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit Training Center');
		this.subheaderService.setBreadcrumbs([
			{ title: 'User Management', page: `user-management` },
			{ title: 'Users',  page: `user-management/users` },
			{ title: 'Edit user', page: `user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.center.centerName = this.cName
		this.center.slogan = this.slogan
		 this.center.tel = this.tel
		 this.center.email = this.email
		 this.center.address = this.address
		 this.center.stateId = this.stateId
		 this.center.cityId = this.cityId
		this.getCitiesForState(this.stateId)
		this.vacancy.centerId = this.centerId;
		this.vacancy.batch = this.batchId;
		this.vacancy.year = this.year;
		this.vacancy.total = this.total;
		this.vacancyForm = this.userFB.group({

      oldPassword: ['', Validators.compose([
				Validators.required
			
			])
			],
  
      password: ['', Validators.compose([
				Validators.required,
        Validators.minLength(8),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'),

				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(100)
      ])
    ],
			
			jwt:[this.user.jwt],
		
			
		},{
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/my-profile`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.vacancyForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.vacancyForm.markAsPristine();
  this.vacancyForm.markAsUntouched();
  this.vacancyForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.vacancyForm.controls;
		/** check form */
		if (this.vacancyForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedPassword = this.preparePassword();
  
	
		
			this.updatePassword(editedPassword, withBack);
			return;
		

	
	}

	/**
	 * Returns prepared data for save
	 */
	preparePassword(): User {
		const controls = this.vacancyForm.controls;
		const _user = new User();
		_user.clear();
		
		
		
    _user.password = controls.password.value;
    _user.oldPassword = controls.oldPassword.value;
    
		return _user;
	}

	/**
	 * Add User


	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updatePassword(_user: User, withBack: boolean = false) {
		
		// this.centerService.UpdateCenter(_center).subscribe(data=>{

		// 	const message = `center successfully updated.`;
		// 	this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
		// 	this.onCLose();

		// })

		this.authService.updatePassword(_user).subscribe(data=>{
      Swal.fire(
				"Password updated successfully",
				'This will take effect from your next login',
				'success'
				)
			const message = `Password updated successfully`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			this.onCLose();

		},error=>{
			Swal.fire(
				error.error.message,
				'Kindly make the neccessay correction and try again ',
				'error'
				)
		})
		
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Update Password';
		if (!this.id || !this.centerId) {
			return result;
		}

	
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
	getCitiesForState(stateId){
		
		this.authService.getCitiesByStateId(stateId).subscribe(data=>{
			this.cities = data
		})

	}

	onCLose(){
		this.reset()
		this.dialogRef.close()
		this.goBackWithId()
	}

	getAllStates(){
     this.authService.getAllStates().subscribe(data=>{
		 this.states = data;
	 })
  }
  getAllCenters(){
		this.centerService.getALLCenters().subscribe(data=>{
      this.centers = data

    }
		
			)

	}
}
