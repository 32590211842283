import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-my-emails',
  templateUrl: './my-emails.component.html',
  styleUrls: ['./my-emails.component.scss']
})
export class MyEmailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
