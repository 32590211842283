import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Center } from '../../../../../core/center/center.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig, PageEvent } from '@angular/material';
import { Subscription } from 'rxjs';
import { CenterService } from '../../../../../core/center/center.service';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud/utils/layout-utils.service';
import { UserEditComponent } from '../../../user-management/users/user-edit/user-edit.component';
import { CenterEditComponent } from '../../../training-center-management/center-edit/center-edit/center-edit.component';
import { VacanciesService } from '../../../../../core/vacancy/vacancies.service';
import { EditPostingComponent } from '../../postings/edit-posting/edit-posting.component';
import { RepostOfficerComponent } from '../../postings/repost-officer/repost-officer.component';
// import { CenterEditComponent } from '../../../.center-edit/center-edit/center-edit.component';
import Swal from 'sweetalert2'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	User,
	UserUpdated,
	Address,
	SocialNetworks,
	selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading
} from '../../../../../core/auth';
import { Clearance } from '../../../../../core/vacancy/clearance.model';
import { SelectionModel } from '@angular/cdk/collections';
import { element } from 'protractor';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as XLSX from 'xlsx';
@Component({
	selector: 'kt-mdcn-monthly-clearance',
	templateUrl: './mdcn-monthly-clearance.component.html',
	styleUrls: ['./mdcn-monthly-clearance.component.scss']
})
export class MdcnMonthlyClearanceComponent implements OnInit {
	exportData: any;
	statsList: [] = [];
	user: User;
	searchKey: string;
	clearanceForm: FormGroup;
	centerId: number;
	month: string;
	year: any;
	officer: number;
	can_clear: boolean = false;
	cDetails: any;
	hasFormErrors = false;
	centers: any;
	allcleared: any;
	stats: any;
	total: number;
	@BlockUI() blockUI: NgBlockUI;

	// Table fields

	listData: MatTableDataSource<Clearance>;
	selection = new SelectionModel<Clearance>(true, []);


	displayedColumns = ['centerName', 'folioNumber', 'firstName', 'lastName', 'batch', 'year', 'month', 'status', 'paid_so_far', 'actions'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	// Subscriptions
	private subscriptions: Subscription[] = [];

	constructor(
		private centerService: CenterService,
		private vacancyService: VacanciesService,
		private layoutUtilsService: LayoutUtilsService,
		private dialog: MatDialog,
		private clearanceFB: FormBuilder,
		private chnageDetect: ChangeDetectorRef
	) { }

	ngOnInit() {

		// load allusers 

		this.createForm();


		this.getAllCenters();

		this.can_clear = this.can("Can_Clear");

	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load users list
	 */



	createForm() {


		this.clearanceForm = this.clearanceFB.group({

			centerId: ['', Validators.required],
			month: ['', Validators.required],
			year: ['', Validators.required],
			status: ['']


		});
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.listData.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.listData.data.forEach(row => this.selection.select(row));

	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: Clearance): string {
		if (!row) {
			return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
	}
	fetchUsers() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.firstName}, ${elem.folioNumber}`,
				id: elem.id.toString(),

				status: elem.status
			});
		});

		this.layoutUtilsService.fetchElements(messages);
	}
	payUsers() {
		const clerance = [];
		this.selection.selected.forEach(elem => {
			console.log('bulk each ', elem);
			clerance.push({
				text: `${elem.folioNumber}`,
				id: elem.cleranceId,
				userId: elem.userId,
				officer: elem.officer,
				folioNumber: elem.folioNumber,
				centerId: elem.centerId,
				status: elem.status,
				postedBy: elem.postedBy,
				trainingType: elem.trainingType

			});
		});
		//alert(JSON.stringify(clerance))
		this.onBulkPaid(clerance)
	}


	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
		}


		const editedClerance = this.prepareClerance();
		this.centerId =  editedClerance.centerId;
			this.month = editedClerance.month;
			this.year = editedClerance.year;
			

		this.getAllClearance(editedClerance.centerId, editedClerance.month, editedClerance.year, editedClerance.status)





		// if (editedCenter.id > 0) {
		// 	this.updatePosting(editedCenter, withBack);
		// 	return;
		// }

		// this.addPosting(editedCenter, withBack);
	}

	prepareClerance(): Clearance {
		const controls = this.clearanceForm.controls;
		const _clerance = new Clearance();
		_clerance.clear();

		_clerance.centerId = controls.centerId.value;
		_clerance.month = controls.month.value;
		_clerance.year = controls.year.value;
		_clerance.status = controls.status.value;



		return _clerance;
	}
	/** FILTRATION */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.lastName = searchText;

		filter.username = searchText;
		filter.email = searchText;
		filter.fillname = searchText;
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete user
	 *
	 * @param _item: User
	 */
	deleteUser(_item: Center) {
		const _title = 'Center Delete';
		const _description = 'Are you sure to permanently delete this center?';
		const _waitDesciption = 'Center is deleting...';
		const _deleteMessage = `Center has been deleted`;

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			//	this.store.dispatch(new UserDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}



	/* UI */
	/**
	 * Returns user roles string
	 *
	 * @param user: User
	 */

	/**
	 * Redirect to edit page
	 *
	 * @param id
	 */
	editUser(id) {

	}
	ViewReason(clerance) {
		const messages = [];

		messages.push({
			text: `${clerance.reason}`,
			id: clerance.id.toString(),

			status: clerance.status
		});


		this.layoutUtilsService.fetchElements(messages);
	}
	exportExcel() {
		const workSheet = XLSX.utils.json_to_sheet(this.exportData, { header: ['Housemanship Monthly Clearance'] });
		//	const workSheet2 = XLSX.utils.json_to_sheet(this.statsList, {header:['Housemanship Monthly Clearance Statistics']});

		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Clearance');
		//XLSX.utils.book_append_sheet(workBook, workSheet2, 'Clearance_Stats');
		var fileName = "Housemanship_monthly_clearance" + Date.now() + ".xlsx";
		XLSX.writeFile(workBook, fileName);
	}
	getAllClearance(centerId, month, year, status?, $from?, $to?) {
		
		this.blockUI.start('Fetching clearance...Please wait')
		this.vacancyService.getCleranceStatus(centerId, month, year, status,$from,$to).subscribe(

			list => {
				this.blockUI.stop()
				let array = list.data.map(item => {
					return item
				})
				

				this.allcleared = list.data;
				this.total = list.total;
				this.exportData = list.reportData
				//	this.statsList.push(this.stats)
				this.stats = list.stat;
				this.listData = new MatTableDataSource(array)


				this.listData.sort = this.sort
				this.listData.paginator = this.paginator
				

				this.chnageDetect.markForCheck();
			},
			error => {
				this.blockUI.stop()
				Swal.fire(
					'Oops',
					error.error.message,
					'error'
				)

			}
		)
	}

	refreshAllClearance(centerId, month, year) {

		this.vacancyService.getCleranceStatus(centerId, month, year).subscribe(

			list => {
				let array = list.data.map(item => {
					return item
				})

				this.allcleared = list.data;
				this.stats = list.stat;
				this.listData = new MatTableDataSource(array)


				this.listData.sort = this.sort
				this.listData.paginator = this.paginator

				this.chnageDetect.markForCheck();
				this.blockUI.stop();
			},
			error => {
				this.blockUI.stop()
				Swal.fire(
					'Oops',
					error.error.message,
					'error'
				)

			}
		)
	}

	public handlePage(e: PageEvent): void {

		let from = e.pageIndex * e.pageSize;
		const to = e.previousPageIndex < e.pageIndex || e.previousPageIndex ==0? (from + e.pageSize) : (((e.previousPageIndex - 1) * (e.pageSize)) + e.pageSize)
		//const to = e.previousPageIndex < e.pageIndex ? (from + e.pageSize ) :  (((e.previousPageIndex- 1) * (e.pageSize))+ e.pageSize)
		this.getAllClearance(this.centerId, this.month, this.year,undefined,from, to)

    }
	getCenterDetails() {
		return this.getAllCenters()
	}

	getAllCenters() {
		this.centerService.getALLCenters().subscribe(data => {
			this.centers = data
			this.centerId =  this.centers[0].id
			let date = new Date();
			this.month = date.toLocaleString('default', { month: 'long' });
			this.year = date.getFullYear();


			this.getAllClearance(this.centerId, this.month, this.year)



		}, error => {

		}

		)

	}

	applyFilter() {

		this.listData.filter = this.searchKey.trim().toLowerCase();
	}

	onCreate() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		this.dialog.open(EditPostingComponent, dialogConfig)
	}
	onRepost() {

		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		this.dialog.open(RepostOfficerComponent, dialogConfig)
	}
	onEdit(center, id) {

		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = "60%";
		dialogConfig.data = center;
		this.dialog.open(EditPostingComponent, dialogConfig)
		//this.router.navigate(['../users/edit', id], { relativeTo: this.activatedRoute });
	}
	onConcludeClearance(center) {


		Swal.fire({
			title: 'Are you sure you want to conclude clearance for the month ',
			text: 'the process is irreversible',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, we have exported the needed clearance',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.value) {
				this.doconcludeClearnce();

			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Clearance cancelled',
					'error'
				)
			}
		})
	}
	onMonthlyPaid(center) {

		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
		}


		const editedClerance = this.prepareClerance();
		Swal.fire({
			title: 'Are you sure you want to pay ' + center.firstName + ' for ' + editedClerance.month,
			text: 'the process is irreversible',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, I want to pay the Officer',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.value) {
				editedClerance.officer = center.officer
				editedClerance.id = center.cleranceId;
				editedClerance.status = center.status;
				editedClerance.amount = 150000;
				editedClerance.folioNumber = center.folioNumber;
				editedClerance.centerId = center.centerId,
					editedClerance.userId = center.userId
				editedClerance.postedBy = center.postedBy
				editedClerance.trainingType = center.trainingType


				this.paySingleOfficer(editedClerance)
				// this.postOfficer(controls.HouseManshipCenters.value)

			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Clearance cancelled',
					'error'
				)
			}
		})
	}


	onBulkPaid(center) {

		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
		}


		const editedClerance = this.prepareClerance();


		Swal.fire({
			title: 'Are you sure you want to pay the selected officer for ' + editedClerance.month,
			text: 'the process is irreversible',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, I want to pay the Officers',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.value) {
				editedClerance.bulk = center



				editedClerance.amount = 150000
				console.log(editedClerance);




				this.payOfficer(editedClerance)


			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Clearance cancelled',
					'error'
				)
			}
		})
	}

	onMonthlyCleared(center) {


		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
		}


		const editedClerance = this.prepareClerance();


		Swal.fire({
			title: 'Are you sure you want to clear ' + center.firstName + ' for ' + editedClerance.month,
			text: 'Once cleared, the process is irreversible',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, I want to clear the Officer',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.value) {
				editedClerance.officer = center.officer
				editedClerance.isCleared = true;
				// alert(JSON.stringify(editedClerance))
				this.clearOfficer(editedClerance)
				// this.postOfficer(controls.HouseManshipCenters.value)

			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Clearance cancelled',
					'error'
				)
			}
		})
	}

	onMonthlyUnCleared(center) {

		this.hasFormErrors = false;
		const controls = this.clearanceForm.controls;
		/** check form */
		if (this.clearanceForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			//this.selectedTab = 0;
			return;
		}


		const editedClerance = this.prepareClerance();


		Swal.fire({
			title: 'Are you sure you do not want to clear ' + center.firstName + ' for ' + editedClerance.month,
			text: 'Once cleared, the process is irreversible',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, proceed to unclear',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.value) {

				Swal.fire({
					title: 'Kindly state the reason for not clearing this officer',
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Continue',
					showLoaderOnConfirm: true,
					preConfirm: (reason) => {

						editedClerance.reason = reason;

						editedClerance.officer = center.officer
						// alert(JSON.stringify(editedClerance))

						// this.postOfficer(controls.HouseManshipCenters.value)


						this.clearOfficer(editedClerance)

					},

				})


			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Clearance cancelled',
					'error'
				)
			}
		})
	}
	clearOfficer(clearance: Clearance) {
		this.blockUI.start('Processing clearance...Please wait')
		this.vacancyService.clearOfficer(clearance).subscribe(data => {
			this.refreshAllClearance(clearance.centerId, clearance.month, clearance.year)

			this.blockUI.stop()
			Swal.fire(
				'Processed',
				'Clearance Processed',
				'success'
			)

		}, error => {
			Swal.fire(
				error.error.message,
				'Clearance was not processed',
				'error'
			)

		})
	}

	can(permissionName: string) {
		return this.centerService.getCapability(permissionName);
	}
	payOfficer(clearance: Clearance) {
		this.blockUI.start('Processing payment...Please wait')
		this.vacancyService.PayOfficer(clearance).subscribe(data => {
			Swal.fire(
				'Paid',
				'Officer Successfully Paid',
				'success'
			)

			this.refreshAllClearance(clearance.centerId, clearance.month, clearance.year)
		}, error => {
			this.blockUI.stop();

			Swal.fire(
				'Oops',
				error.error.message,
				'error'
			)

		}
		)
	}

	doconcludeClearnce() {
		this.blockUI.start('Processing...this will take a while')
		this.vacancyService.concludeClearance().subscribe(data => {
			Swal.fire(
				'Done',
				'Clearance Concluded',
				'success'
			)
			this.blockUI.stop();
		}, error => {
			this.blockUI.stop();

			Swal.fire(
				'Oops',
				error.error.message,
				'error'
			)

		}
		)
	}

	paySingleOfficer(clearance: Clearance) {
		this.blockUI.start('Processing payment...Please wait')
		this.vacancyService.PaySingleOfficer(clearance).subscribe(data => {
			Swal.fire(
				'Paid',
				'Officer Successfully Paid',
				'success'
			)
			this.blockUI.stop();
			this.refreshAllClearance(clearance.centerId, clearance.month, clearance.year)
		}, error => {
			this.blockUI.stop();

			Swal.fire(
				'Oops',
				error.error.message,
				'error'
			)

		}
		)
	}



}
