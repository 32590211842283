export class Bank {
    id: number;
    centerName: string;
    centerId:number;
    year:string;
    userId:any;
    batch:number;
    batchName:string;
    email:string;
    firstName:string;
    lastName:string;
    jwt:string;
    officer:number;
    bankName:string;
    accountNumber:string;
    bvn:any;

    clear(): void {
        this.id = undefined;
        this.centerName = '';
        this.centerId = undefined;
        this.year='';
        this.bankName= '';
        this.bvn = undefined;
        this.userId = undefined;
        this.officer = undefined
        
        this.accountNumber = '';
        this.batchName='';
       

        this.jwt = localStorage.getItem('token')
       
       
    }



}