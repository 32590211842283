// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import {ImageCropperComponent, CropperSettings} from 'ng2-img-cropper';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmAccountNumberValidator } from './confirm-account.validator';

@Component({
  selector: 'kt-officer-documentation',
  templateUrl: './officer-documentation.component.html',
  styleUrls: ['./officer-documentation.component.scss']
})
export class OfficerDocumentationComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	confirmForm:FormGroup;
	loading = false;
	qRegister = false;
	errors: any = [];
	folioNumber :any;
	regType : any;
	regDate: any;
  practiceType: string;
  DoctData: any;
  banks:any;
  message:any;
  agreeMessage:any;
  imagePath:any;
  imgURL:any;
  data:any;
  hasMinError:boolean;
  cropperSettings: CropperSettings;
	@BlockUI() blockUI: NgBlockUI;
@ViewChild('cropper', undefined) cropper:ImageCropperComponent;
@ViewChild('name',undefined) nameField:ElementRef;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private datePipe: DatePipe,
		
	) {
		this.unsubscribe = new Subject();
		this.cropperSettings = new CropperSettings();
		this.cropperSettings.width = 100;
        this.cropperSettings.height = 100;
        this.cropperSettings.croppedWidth =100;
        this.cropperSettings.croppedHeight = 100;
        this.cropperSettings.canvasWidth = 400;
        this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.noFileInput = true;
    this.data = {};
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
   
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initConfirmForm() {

		
		this.confirmForm = this.fb.group({
			
			folioNumber: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			
			
			
			
		});
	}

	initRegisterForm() {

		
		this.registerForm = this.fb.group({
			center: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			folioNumber: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			dateStarted: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],

			phone: ['', Validators.compose([
				Validators.required,
				Validators.minLength(11),
				Validators.maxLength(100)
			])
			],
			
			
			email: [this.DoctData.email, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			]),
			
			],
			
			file: ['', Validators.compose([
				Validators.required
				
			]),
			],
			fileSource: ['', Validators.compose([
				
				
			]),
			],
			agree: [false, Validators.compose([Validators.required])]
			
		}, {
			validator: [ConfirmPasswordValidator.MatchPassword, ConfirmAccountNumberValidator.MatchAccount]

		});
	}

	/**
	 * Form Submit
	 */

	checkLength(controlName:string,min:number,max:number)
	{
		const control = this.registerForm.controls[controlName];
        control.value
		if(control.value.toString().length<min){
			this.hasMinError= true;

		 	const result = control.hasError('minlength') && (control.dirty || control.touched);
		 return result;
	}
}
	 confirm(){
		
		const controls = this.confirmForm.controls;
	
		
		if (this.confirmForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
	let dateOfIssue = controls.dateOfIssue.value;
	dateOfIssue =this.datePipe.transform(dateOfIssue, 'yyyy-MM-dd');
		this.auth.getDoctorStatus(controls.folioNumber.value,dateOfIssue).subscribe(data=>{
			
			this.folioNumber = controls.folioNumber.value
			//this.regType = controls.regType.value
			this.regDate = data.doctor.regDate
			let currentDate:any = new Date();
			 currentDate =	this.datePipe.transform(currentDate, 'yyyy-MM-dd');
           
			if(data.doctor.provisionalRegStatus==false || data.doctor.provisionalExpiryDate < currentDate)
			{
			
				Swal.fire(
					'Not Eligible For House Job',
					`Dear ${data.doctor.firstName} , the period for you to secure posting is expired.You are not eligible for house job posting `,
					'error'
				  )
			}else {
			localStorage.setItem('DocData',data)	
			this.router.navigateByUrl('/auth/officer-reg');
			}
			this.practiceType = data.doctor.practiceType
			this.loading = false;
		   this.qRegister = true;
		},error=>{
			Swal.fire(
				error.error.message,
				`Make the neccessary correction and try again `,
				'error'
			  )
			
			//this.authNoticeService.setNotice('Folio number not found,please check and try again or CONTACT MDCN to get one ', 'danger');
			this.loading = false;
		})

	 }

	 preview(files) {
		if (files.length === 0)
		  return;
	 
		var reader = new FileReader();
		this.imagePath = files;
		reader.readAsDataURL(files[0]); 
		reader.onload = (_event) => { 
		  this.imgURL = reader.result; 
		 
		}
	  }


	submit() {
		
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>{
				controls[controlName].markAsTouched()
				
			}
			);
			return;
		}

		

		if (!controls.agree.value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			this.agreeMessage= "You must agree the terms and condition";
			return;
		}
		
		const _user: User = new User();
		_user.clear();
		_user.email = controls.email.value;
		_user.username = controls.username.value;
		_user.firstName = controls.firstName.value;
		_user.otherName = controls.otherName.value;
		_user.lastName = controls.lastName.value;
		_user.lastName = controls.lastName.value;
		_user.password = controls.password.value;
		_user.accountNumber = controls.accountNumber.value;
		_user.bvn = controls.bvn.value;
		_user.bankName = controls.bankName.value;
	//	_user.regType = controls.regType.value;
		_user.folioNumber = controls.folioNumber.value;
       _user.regDate = controls.regDate.value;
		_user.mdcn_passport = this.DoctData.photo;
		_user.registrationNumber = this.DoctData.provisionalRegNo;
		_user.phone =  this.DoctData.phone;
		_user.provisionalExpiryDate  = this.DoctData.provisionalExpiryDate;
		_user.previousName = this.DoctData.previousName;
		_user.practiceType = controls.practiceType.value;
		_user.dateOfIssue = this.DoctData.dateOfIssue;
		_user.role = 2;
		_user.gender = this.DoctData.gender;
		_user.institution = this.DoctData.institution;
		
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
			  confirmButton: 'btn btn-success',
			  cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		  })
		  
		  swalWithBootstrapButtons.fire({
			title: 'Ensure your details are correct. ',
			text: 'You will not be able to edit this information after submission',
			icon: 'warning',
			showCancelButton: true,
			
			cancelButtonText: 'Review Information',
			confirmButtonText: 'Proceed to Submit'
		  }).then((result) => {
			if (result.value) {
				this.loading = true;
				this.blockUI.start('Prcocessing...');
				const formData = new FormData();
				formData.append('file', this.registerForm.get('fileSource').value);
					this.auth.uploadFile(formData).subscribe(data=>{
						_user.pic=data.message;
						
							  this.auth.register(_user).pipe(
								tap(data=>{
					
								},error=>{
					
								},
								),takeUntil(this.unsubscribe),
								finalize(() => {
									this.loading = false;
									this.blockUI.stop();
									this.cdr.markForCheck();
								}),
							).subscribe(
						data=> {
							Swal.fire(
								'Created',
								'Congratulations you have successfully registered a profile',
								'success'
							  )
							  setTimeout(x=>{
								this.router.navigateByUrl('/auth/login');
			
							  }, 2000)
							
						
						},error=>{
				   // alert(JSON.stringify(error))
					Swal.fire(
					  error.error.message,
								'Please make the necessary correction and try again',
								'error'
							  )
			
				  })
						
				
				}
				
					)
			
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			  this.returnFocus();
			}
		  })
	
		
	}

	// get f(){
	// 	return this.registerForm.controls
	//   }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	onFileChange(event) {
  
		if (event.target.files.length > 0) {
		  const file = event.target.files[0];
		  
		  var mimeType = file.type;
		 // alert(mimeType)
		if (mimeType.match(/image\/*/) == null) {
		  this.message = "Only image (jpg,png) file  are supported";
		  return;
		}
		else
		 {
			if(file.size/1024 > 100)
			{
			  this.message = "Image cannot be more than 100kb. the uploaded image is "+ file.size/1024 + "kb in size";
			  return;
			}
			this.message = null;
			
			var image = new Image();
		
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;
			
			that.cropper.setImage(image);
	 
		};
		
	 
		myReader.readAsDataURL(file);
		this.registerForm.patchValue({
			fileSource: file
		  });

		
	 
		var reader = new FileReader();
		this.imagePath = file;
		reader.readAsDataURL(file); 
		reader.onload = (_event) => { 
		  this.imgURL = reader.result; 
		 
		}
		}
		}
	  }

	  returnFocus(){
 this.nameField.nativeElement.focus()
	  }

	  fileChangeListener($event) {
		var image:any = new Image();
		var file:File = $event.target.files[0];
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;
			that.cropper.setImage(image);
	 
		};
	 
		myReader.readAsDataURL(file);
	}
	 
}
